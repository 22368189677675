import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { TabsTypeList } from "@pages/AdsAnalitics/components/SanKey/utils";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { ExpandableTable } from "@components/ExpandebleTable";
import { useKeywordStore } from "src/store/keyword.state";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { CanvasApi } from "@services/canvas/canvas.api";
import styles from "./styles.module.scss";
import SearchIcon from "src/assets/icons/SearchIcon.svg";
import { formatData } from "../utils";
import {
  fetcherMap,
  getColumns,
  getSaturationKey,
  isTotalFirst,
  level1Map,
  level2Map,
  removeTotalChildren,
  toIsoDate,
  updateNestedRecord,
  updateRecordChildren,
} from "./utils";
import { INestedData, TableItem } from "../utils.types";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import classNames from "classnames";
import { Select } from "@components/Select";

interface CampaignsTableProps {
  isWhatIf?: boolean;
  customAsin?: string[];
  autoHeight?: boolean;
  showSearch?: "show" | "hide";
}

export const CampaignsTable = ({
  isWhatIf,
  customAsin,
  autoHeight,
  showSearch,
}: CampaignsTableProps) => {
  const { keyword, setKeywords } = useKeywordStore();
  const { dateRange } = useDashboardStore();
  const { adSpend: saturation } = useAdsAnaliticsStore().adSlides;
  const [isLoading, setIsLoading] = useState(false);
  const canvasApi = useMemo(() => new CanvasApi(), []);

  const [temporaryData, setTemporaryData] = useState<TableItem[] | undefined>();

  const [tableData, setTableData] = useState<any[]>([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [expandedRowsData, setExpandedRowsData] = useState<Record<string, any>>(
    {},
  );
  const [previousSaturation, setPreviousSaturation] = useState(saturation);

  const [saturationKeys, setSaturationKeys] = useState<{
    level1: string[];
    level2: string[];
    level3: string[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const [tableKeys, setTableKeys] = useState<React.Key[]>([]);
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");

  const [tooltipTabsTitle, setTooltipTabsTitle] = useState("");
  const [tooltipKeysTitle, setTooltipKeysTitle] = useState("");

  const [asin, setAsin] = useState<string[]>(customAsin || []);

  const { startDate, endDate } = dateRange;
  const from = toIsoDate(startDate);
  const to = toIsoDate(endDate);

  const tableRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (tableRef.current) {
      setHeight(tableRef.current?.offsetHeight);
    }
  }, [tableRef.current?.offsetHeight, tableData]);

  useEffect(() => {
    setAsin(customAsin || []);
  }, [customAsin]);

  /**
   * Fetch top-level data.
   */
  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true);
      try {
        const fetchMethod = fetcherMap[selectedTab];
        if (!fetchMethod) return;

        const fetchedData = await fetchMethod(
          from,
          to,
          saturation,
          asin,
          saturationKeys.level1,
        );

        const shapedData: INestedData[] = fetchedData.map((item, index) => ({
          ...item,
          key: index,
          level: 1,
          hasFetchedChildren: false,
          children: [],
        }));
        setTemporaryData(shapedData);
      } catch (error) {
        setTemporaryData([]);
        console.error("Error fetching performance data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTableData();
  }, [
    selectedTab,
    dateRange,
    canvasApi,
    asin,
    saturationKeys.level1,
    saturation,
  ]);

  // Recursively find nested records by their key path
  const findRecordByKey = (data: any[], key: string): any => {
    for (const item of data) {
      if (item.key.toString() === key) return item;
      if (item.children?.length) {
        const found = findRecordByKey(item.children, key);
        if (found) return found;
      }
    }
    return null;
  };

  const updateAllExpandedRows = useCallback(async () => {
    if (!temporaryData) return;

    setIsLoading(true);

    try {
      // First, update top-level data with current saturation and saturation keys
      const fetchMethod = fetcherMap[selectedTab];
      if (fetchMethod) {
        const fetchedData = await fetchMethod(
          from,
          to,
          saturation,
          asin,
          saturationKeys.level1, // Use current saturation keys
        );

        const shapedData: INestedData[] = fetchedData.map((item, index) => ({
          ...item,
          key: index,
          level: 1,
          hasFetchedChildren: false,
          children: [],
        }));

        let updatedData = [...shapedData];

        // Then recursively update all expanded children
        for (const key of expandedRowKeys) {
          const keyPath = key.toString().split("-");
          const topLevelKey = parseInt(keyPath[0]);

          if (keyPath.length === 1) {
            // This is a top-level expansion
            const record = updatedData.find((item) => item.key === topLevelKey);

            if (record) {
              const getData = level1Map[selectedTab];
              if (getData) {
                // Use the saturation keys for the next level
                const keys = saturationKeys[`level${Number(record.level) + 1}`];
                const childrenData = await getData(
                  from,
                  to,
                  record,
                  saturation,
                  asin,
                  keys, // Pass the keys explicitly
                );

                const shapedChildren = childrenData.map(
                  (child: any, index: number) => ({
                    ...child,
                    key: `${record.key}-${index}`,
                    level: Number(record.level) + 1,
                    hasFetchedChildren: false,
                    performance: child.targeting_text || child.campaign_name,
                    campaign_name: child.search_term,
                    ad_type: child.search_term || child.campaign,
                    target: child.campaign_name,
                    match_type: child.target || child.campaign_name,
                    match_type_key: child.match_type,
                    performanceKey: child.performance,
                    ad_typeKey: record.ad_type,
                    funnelKey: record.funnel,
                    funnel: child.campaign_name || child.funnel,
                    children: [],
                  }),
                );

                setExpandedRowsData((prev) => ({
                  ...prev,
                  [record.key]: shapedChildren,
                }));

                updatedData = updateRecordChildren(
                  updatedData,
                  record.key,
                  shapedChildren,
                );
              }
            }
          } else {
            // This is a nested expansion
            const record = findRecordByKey(updatedData, key.toString());

            if (record) {
              const getData = level2Map[selectedTab];
              if (getData) {
                // Use the saturation keys for the next level
                const keys = saturationKeys[`level${Number(record.level) + 1}`];
                const childrenData = await getData(
                  from,
                  to,
                  record,
                  saturation,
                  asin,
                  keys, // Pass the keys explicitly
                );

                const shapedChildren = childrenData.map(
                  (child: any, index: number) => ({
                    ...child,
                    key: `${record.key}-${index}`,
                    level: Number(record.level) + 1,
                    hasFetchedChildren: false,
                    performance: child.targeting_text || child.campaign_name,
                    campaign_name: child.search_term,
                    ad_type: child.search_term || child.campaign,
                    target: child.campaign_name,
                    match_type: child.target || child.campaign_name,
                    match_type_key: child.match_type,
                    performanceKey: child.performance,
                    ad_typeKey: record.ad_type,
                    funnelKey: record.funnel,
                    funnel: child.campaign_name || child.funnel,
                    children: [],
                  }),
                );

                setExpandedRowsData((prev) => ({
                  ...prev,
                  [record.key]: shapedChildren,
                }));

                updatedData = updateRecordChildren(
                  updatedData,
                  record.key,
                  shapedChildren,
                );
              }
            }
          }
        }

        setTemporaryData(updatedData);
      }
    } catch (error) {
      console.error(
        "Error updating expanded rows on saturation change:",
        error,
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    temporaryData,
    expandedRowKeys,
    selectedTab,
    saturation,
    from,
    to,
    asin,
    saturationKeys,
  ]);

  // Update data when saturation changes but preserve expanded state
  useEffect(() => {
    if (previousSaturation !== saturation && expandedRowKeys.length > 0) {
      updateAllExpandedRows();
    }

    setPreviousSaturation(saturation);
  }, [saturation, previousSaturation, expandedRowKeys, updateAllExpandedRows]);

  // Handle updating selected nested records when saturation changes
  useEffect(() => {
    const updateSelectedNestedRecords = async () => {
      const nestedSelectedRows = tableData.filter(
        (row) => tableKeys.includes(row.key) && row.level > 1,
      );

      if (nestedSelectedRows.length > 0) {
        try {
          setIsLoading(true);
          const updates = nestedSelectedRows.map((row) => {
            // Get the appropriate saturation keys for this level
            const keys = saturationKeys[`level${row.level}`] || [];

            return updateNestedRecord(row, tableData, {
              from,
              to,
              selectedTab,
              saturation,
              asins: asin,
              saturationKeys: keys, // Pass the keys explicitly
            });
          });

          const results = await Promise.all(updates);
          const updatedData = results
            .reduce((acc, curr) => {
              return curr.map((item) => {
                const existing = acc.find((i) => i.key === item.key);
                return existing || item;
              });
            }, tableData)
            .filter((row) =>
              Object.keys(row).forEach((key) => row[key] !== "Total"),
            );

          setTemporaryData(updatedData);
        } catch (error) {
          console.error(
            "Error updating nested records on saturation change:",
            error,
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    updateSelectedNestedRecords();
  }, [
    saturation,
    saturationKeys,
    expandedRowKeys,
    tableKeys,
    tableData,
    from,
    to,
    selectedTab,
    asin,
  ]);

  /**
   * Handles row expansion.
   * On expand, we fetch children data (campaigns / search terms / targets, etc.).
   * On collapse, we remove them from `expandedRowKeys` and optionally clear children.
   */
  const handleExpand = async (expanded: boolean, record: any) => {
    if (!expanded) {
      setExpandedRowKeys((prev) => prev.filter((k) => k !== record.key));
      return;
    }

    setExpandedRowKeys((prev) => [...prev, record.key]);

    try {
      setIsLoading(true);
      const getData =
        Number(record.level) === 1
          ? level1Map[selectedTab]
          : level2Map[selectedTab];
      if (!getData) return;

      // Use the appropriate saturation keys for this level
      const keys = saturationKeys[`level${Number(record.level) + 1}`] || [];

      const childrenData = await getData(
        from,
        to,
        record,
        saturation,
        asin,
        keys, // Pass the keys explicitly
      );

      const shapedChildren = childrenData.map((child: any, index: number) => ({
        ...child,
        key: `${record.key}-${index}`,
        level: Number(record.level) + 1,
        hasFetchedChildren: false,
        performance: child.targeting_text || child.campaign_name,
        campaign_name: child.search_term,
        ad_type: child.search_term || child.campaign,
        target: child.campaign_name,
        match_type: child.target || child.campaign_name,
        match_type_key: child.match_type,
        performanceKey: child.performance,
        ad_typeKey: record.ad_type,
        funnelKey: record.funnel,
        funnel: child.campaign_name || child.funnel,
        children: [],
      }));

      setExpandedRowsData((prev) => ({
        ...prev,
        [record.key]: shapedChildren,
      }));

      setTemporaryData(
        (prev) =>
          prev && updateRecordChildren(prev, record.key, shapedChildren),
      );
    } catch (error) {
      console.error("Error fetching children data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Every time performanceData or the selectedTab changes,
   * recalculate `tableData` so that the table sees the updated structure.
   */
  useEffect(() => {
    const prepareTableData = () => {
      const data = isTotalFirst(temporaryData)
        ? temporaryData?.reverse()
        : temporaryData;

      const finalData = formatData({
        data: removeTotalChildren(data) || [],
        openTooltip: setTooltipTabsTitle,
        openedTooltip: tooltipTabsTitle,
        keyTooltip: tooltipKeysTitle,
        setKeyTooltip: setTooltipKeysTitle,
        keyword,
        setKeywords,
        withKeywords: selectedTab === "Campaigns",
      });

      setTableData(finalData);
    };

    prepareTableData();
  }, [
    temporaryData,
    tooltipTabsTitle,
    tooltipKeysTitle,
    keyword,
    setKeywords,
    selectedTab,
  ]);

  useEffect(() => {
    setExpandedRowKeys([]);
    setExpandedRowsData({});
    setSaturationKeys({
      level1: [],
      level2: [],
      level3: [],
    });
  }, [selectedTab]);

  /**
   * Handle row selection changes (checkbox in first column).
   */
  const onSelectChange = async (
    newSelectedRowKeys: string[],
    selectedRows: any[],
  ) => {
    setTableKeys(newSelectedRowKeys);

    // Group rows by level for saturation keys
    const keysByLevel = {
      level1: new Set<string>(),
      level2: new Set<string>(),
      level3: new Set<string>(),
    };

    selectedRows.forEach((row) => {
      const level = `level${row.level}`;
      const key = getSaturationKey(row, selectedTab);
      if (key && level in keysByLevel) {
        keysByLevel[level].add(key);
      }
    });

    // Convert Sets to Arrays for each level
    const newSaturationKeys = {
      level1: Array.from(keysByLevel.level1),
      level2: Array.from(keysByLevel.level2),
      level3: Array.from(keysByLevel.level3),
    };

    // Update saturation keys state
    setSaturationKeys(newSaturationKeys);

    // Helper function to get keys for a specific level
    const getSaturationKeysForLevel = (level: number) => {
      return Array.from(keysByLevel[`level${level}`]) as string[];
    };

    // Update data for selected rows
    if (temporaryData) {
      const updatedData = [...temporaryData];
      for (const row of selectedRows) {
        const updatedRecords = await updateNestedRecord(row, updatedData, {
          from,
          to,
          selectedTab,
          saturation,
          asins: asin,
          saturationKeys: getSaturationKeysForLevel(row.level),
          expandedKeys: expandedRowKeys as string[],
        });
        setTemporaryData(updatedRecords);
      }
    }
  };

  // Restore expanded rows when temporary data changes
  useEffect(() => {
    if (temporaryData && expandedRowKeys.length > 0) {
      const restoreExpandedRows = async () => {
        for (const key of expandedRowKeys) {
          const record = findRecordByKey(temporaryData, key.toString());
          if (
            record &&
            !record.children?.length &&
            expandedRowsData[key.toString()]
          ) {
            setTemporaryData(
              (prev) =>
                prev &&
                updateRecordChildren(
                  prev,
                  key as number,
                  expandedRowsData[key.toString()],
                ),
            );
          }
        }
      };

      restoreExpandedRows();
    }
  }, [temporaryData, expandedRowKeys, expandedRowsData]);

  const rowSelection = {
    tableKeys,
    onChange: onSelectChange,
    onSelect: async (record: any, selected: boolean) => {
      if (selected && Number(record.level) > 0) {
        try {
          setIsLoading(true);
          const keys = saturationKeys[`level${Number(record.level)}`] || [];
          const updatedData = await updateNestedRecord(record, tableData, {
            from,
            to,
            selectedTab,
            saturation,
            asins: asin,
            saturationKeys: keys, // Pass the keys explicitly
          });
          setTemporaryData(updatedData);
        } catch (error) {
          console.error("Error updating selected record:", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  };

  return (
    <>
      <TabsTypeList
        isFunnelChart
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      {showSearch === "show" && (
        <div className={styles.searchRow}>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search"
            />
            <img src={SearchIcon} alt="Search" className={styles.searchIcon} />
          </div>
          <Select
            options={["All", "Other"]}
            value={status}
            onChange={(value: string) => console.log(value)}
            placeholder={"Filters"}
            className={classNames(styles.select__dropdown)}
          />
        </div>
      )}

      <div
        className={classNames(styles.table, {
          [styles.autoHeight]: autoHeight,
        })}
        ref={tableRef}
      >
        <ExpandableTable
          columns={getColumns(selectedTab, isWhatIf)}
          data={tableData}
          rowSelection={rowSelection}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            rowExpandable: () => false,
          }}
          scroll={{ x: "max-content", y: `${autoHeight ? height : 400}px` }}
          adSalesTab={selectedTab}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
