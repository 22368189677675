export const staticData = [
  { date: "Jun", engagement: 0.56, conversionRate: 0.34 },
  { date: "Jul", engagement: 0.53, conversionRate: 0.32 },
  { date: "Aug", engagement: 0.51, conversionRate: 0.31 },
  { date: "Sep", engagement: 0.52, conversionRate: 0.33 },
  { date: "Oct", engagement: 0.54, conversionRate: 0.35 },
  { date: "Nov", engagement: 0.54, conversionRate: 0.36 },
  { date: "Dec", engagement: 0.55, conversionRate: 0.38 },
  { date: "Jan", engagement: 0.56, conversionRate: 0.39 },
  { date: "Feb", engagement: 0.58, conversionRate: 0.4 },
  { date: "Mar", engagement: 0.61, conversionRate: 0.41 },
  { date: "Apr", engagement: 0.63, conversionRate: 0.43 },
  { date: "May", engagement: 0.68, conversionRate: 0.45 },
  { date: "Jun", engagement: 0.67, conversionRate: 0.46 },
  { date: "Jul", engagement: 0.66, conversionRate: 0.44 },
  { date: "Aug", engagement: 0.64, conversionRate: 0.42 },
];
