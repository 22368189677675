import { useState } from "react";
import styles from "./styles.module.scss";
import { Select } from "@components/Select";
import {
  Line,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { staticData, chartColors, formatCurrency } from "./mock";
import classNames from "classnames";
import { RadarChartWidget } from "./RadarChartWidget";
import arrowChartUp from "@assets/icons/grommet-icons_line-chart_green.svg";
import arrowChartDown from "@assets/icons/grommet-icons_line-chart.svg";

export const AspectsWidget = ({ data }) => {
  const [range, setRange] = useState("Week");

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartWrapper}>
        <div className={styles.legend}>
          <h2>Review Score</h2>
          <Select
            options={["Week", "Month", "Year"]}
            value={status}
            onChange={(value: string) => setRange(value)}
            placeholder={range}
            className={classNames(styles.select__dropdown)}
          />
        </div>
        <ResponsiveContainer width="100%" height={306}>
          <ComposedChart
            width={1252}
            height={306}
            data={staticData}
            margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
          >
            <CartesianGrid vertical={true} stroke="#5c5c5a" opacity={0.1} />
            <XAxis
              dataKey="date"
              stroke="#5c5c5a"
              padding={{ left: 20, right: 20 }}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tickLine={false}
              domain={[0, 5]}
            />

            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tickLine={false}
              domain={[0.2, 0.6]}
              tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
            />

            <Tooltip
              cursor={{ stroke: "white", strokeDasharray: "4 4" }}
              formatter={(value: any, name: string) => {
                if (name === "conversionRate")
                  return [`${(value * 100).toFixed(1)}%`, "Conversion"];
                return [value, name];
              }}
            />

            <Line
              yAxisId="left"
              type="monotone"
              dataKey="unitsSold"
              stroke={chartColors.unitsSold}
              strokeWidth={2}
              dot={false}
              activeDot={{
                r: 5,
                fill: "#fff",
                stroke: chartColors.unitsSold,
                strokeWidth: 2,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.infoBlocks}>
          <div className={styles.infoBlock}>
            <h3>Problem</h3>
            <div className={styles.content}>
              <span>
                <img src={arrowChartDown} alt="down" />
                {data.ploblem.title}
              </span>
              <p>{data.ploblem.text}</p>
            </div>
          </div>
          <div className={styles.infoBlock}>
            <h3>Solution</h3>
            <div className={styles.content}>
              <span className={styles.label}>
                <img src={arrowChartUp} alt="up" />
                {data.solution.title}
              </span>
              <p>{data.solution.text}</p>
              <div className={styles.potential}>
                <span>Potential sales lift:</span>{" "}
                <span>{formatCurrency(data.solution.potential_value)}</span>
              </div>
            </div>
          </div>
        </div>
        <RadarChartWidget />
      </div>
    </div>
  );
};
