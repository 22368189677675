import Options from "./components/Options";
import Generate from "./components/Generate";
import KeywordSearch from "./components/KeywordSearch";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import OptimisationPageWrapper from "../../components/OptimisationPageWrapper";
import { ProductMaterials } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductsStore } from "src/store/product.state";

function OptimisationDetails() {
  const [showNewBlocks, setShowNewBlocks] = useState(false);
  const [isReturning, setIsReturning] = useState(false);

  const handleGenerateClick = () => {
    setShowNewBlocks(true);
  };

  const handleBackClick = () => {
    setIsReturning(true);
    setShowNewBlocks(false);
    setIsReturning(false);
  };

  const { productDataByAsin, productMaterials, setProductMaterials } =
    useProductsStore();
  const { selectedProduct } = useProductStore();

  useEffect(() => {
    const fetchProductData = async () => {
      if (!selectedProduct) return;

      try {
        const [productData, imagesData, videosData] = await Promise.all([
          AmazonService.products.getProductDataByAsin([selectedProduct.asin]),
          AmazonService.products.getProductDataByAsinImgs([
            selectedProduct.asin,
          ]),
          AmazonService.products.getProductDataByAsinVideos([
            selectedProduct.asin,
          ]),
        ]);

        setProductMaterials({
          images: imagesData as any,
          videos: videosData as any,
        });
      } catch (error) {
        console.error("Error fetching product data by ASIN:", error);
      }
    };

    fetchProductData();
  }, [selectedProduct]);

  return (
    <OptimisationPageWrapper onBackClick={showNewBlocks && handleBackClick}>
      123
      <div className={styles.optimisationContainer}>
        {!showNewBlocks ? (
          <>
            <Generate
              onGenerateClick={handleGenerateClick}
              title={productDataByAsin.item_name}
              points={productDataByAsin.bullet_points}
              images={productMaterials.images}
            />
            <Options asin={productDataByAsin.asin} />
          </>
        ) : (
          <KeywordSearch
            onBackClick={handleBackClick}
            isReturning={isReturning}
          />
        )}
      </div>
    </OptimisationPageWrapper>
  );
}

export default OptimisationDetails;
