import styles from "./styles.module.scss";
import { Card } from "./components/Card";
import { Materials } from "./components/Materials";
import { Barrels } from "./components/Barrels";

export const CROWidget = ({ data }) => {
  const { listing_value, photo_value, video_value, content_value } =
    data.barrels;
  const { imgs_gallery, video_gallery, title, bullets, terms } = data.item;

  return (
    <div className={styles.wrapper}>
      <Materials imgs_gallery={imgs_gallery} video_gallery={video_gallery} />
      <Card title={title} bullets={bullets} terms={terms} />
      <Barrels
        listing={listing_value}
        photo={photo_value}
        video={video_value}
        content={content_value}
      />
    </div>
  );
};
