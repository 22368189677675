import styles from "./styles.module.scss";
import LiquidFillGauge from "react-liquid-gauge";
import Basic_Gauge from "@assets/icons/marketIntelligence/Basic_Gauge.png";
interface BarrelsProps {
  listing: number;
  photo: number;
  video: number;
  content: number | null;
}

export const Barrels = ({ listing, photo, video, content }: BarrelsProps) => {
  const values = [listing, photo, video, content ?? 0];
  const average = values.reduce((a, b) => a + b, 0) / values.length;
  const rotation = (average / 100) * 180 - 90;

  return (
    <div className={styles.wrapper}>
      <div className={styles.gaugeBlock}>
        <img
          src={Basic_Gauge}
          alt="Basic Gauge"
          className={styles.gaugeImage}
        />
        <div className={styles.gaugeText}>
          <h3>Optimization is Required</h3>
          <p>
            Our Full Product Optimization Score indicates good quality with the
            potential for further improvements
          </p>
        </div>
      </div>
      <div className={styles.barrels}>
        <div className={styles.barrel}>
          <h3>Listing optimization</h3>
          <LiquidFillGauge
            value={listing}
            style={{ marginLeft: "-250px" }}
            height={80}
            width={800}
            textSize={1}
            offsetX={20}
            textOffsetX={-110}
            textOffsetY={20}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={3}
            gradient
            circleStyle={{ display: "none" }}
            waveStyle={{
              fill: "#80C67A",
              width: "400%",
              r: "600",
            }}
            textStyle={{
              className: styles.text,
              fill: "#fff",
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              textAnchor: "start",
              dominantBaseline: "middle",
            }}
            waveTextStyle={{
              className: styles.text,
              fill: "#fff",
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              textAnchor: "start",
              dominantBaseline: "middle",
            }}
          />
        </div>
        <div className={styles.barrel}>
          <h3>Photo optimization</h3>
          <LiquidFillGauge
            value={photo}
            style={{ marginLeft: "-250px" }}
            height={80}
            width={800}
            textSize={1}
            offsetX={20}
            textOffsetX={-110}
            textOffsetY={20}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={3}
            gradient
            circleStyle={{ display: "none" }}
            waveStyle={{
              fill: "#1BB08C",
              width: "400%",
              r: "600",
            }}
            textStyle={{
              className: styles.text,
              fill: "#fff",
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              textAnchor: "start",
              dominantBaseline: "middle",
            }}
            waveTextStyle={{
              className: styles.text,
              fill: "#fff",
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              textAnchor: "start",
              dominantBaseline: "middle",
            }}
          />
        </div>
        <div className={styles.barrel}>
          <h3>Video optimization</h3>
          <LiquidFillGauge
            value={video}
            style={{ marginLeft: "-250px" }}
            height={80}
            width={800}
            textSize={1}
            offsetX={20}
            textOffsetX={-110}
            textOffsetY={20}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={3}
            gradient
            circleStyle={{ display: "none" }}
            waveStyle={{
              fill: "#6B4DBA",
              width: "400%",
              r: "600",
            }}
            textStyle={{
              className: styles.text,
              fill: "#fff",
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              textAnchor: "start",
              dominantBaseline: "middle",
            }}
            waveTextStyle={{
              className: styles.text,
              fill: "#fff",
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              textAnchor: "start",
              dominantBaseline: "middle",
            }}
          />
        </div>
        <div className={styles.barrel}>
          <h3>A+ content optimization</h3>
          <span>Not optimized</span>
        </div>
      </div>
    </div>
  );
};
