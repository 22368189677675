import { useState } from "react";
import styles from "./styles.module.scss";
import { Stepper } from "./components/Stepper";
import { stepperMock, tabsMock } from "./mock";
import { TabsComponent } from "./components/Tabs";

export const Details = () => {
  console.log("tabsMock", tabsMock);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [activeTab, setActiveTab] = useState<string>(Object.keys(tabsMock)[0]);

  return (
    <div className={styles.detailsWrapper}>
      <Stepper
        currStep={activeStep}
        steps={stepperMock}
        totalLabel="The result of growth after optimization"
        totalValue="$20,070.59"
      />
      <TabsComponent
        setActiveTab={setActiveTab}
        setActiveStep={setActiveStep}
        activeTab={activeTab}
        data={tabsMock}
      />
    </div>
  );
};
