import { AdSlides } from "src/store/ads-analitics.store";
import { FunnelAdsData } from "./lib";
import { tooltipFormatter } from "./tooltip";

export const seriesConfig = [
  { name: "Amazon Ads", color: "#f39c6b" },
  { name: "Amazon Organic", color: "#81c784" },
  { name: "Organic Social", color: "#8e44ad" },
  { name: "DSP", color: "#3498db" },
];

const seriesColors: Record<string, string> = {
  "Amazon Ads": "#f39c6b",
  "Forecast Amazon Ads": "#FEB38A",
  "Amazon Organic": "#81c784",
  "Forecast Amazon Organic": "#98E191",
  "Organic Social": "#8e44ad",
  DSP: "#3498db",
};

const dates = [
  new Date(2024, 0, 1), // Impressions
  new Date(2024, 0, 2), // Clicks
  new Date(2024, 0, 3), // cart
  new Date(2024, 0, 4), // Orders
];

let tooltipX = 0;

export const getOption = (
  activeSeries: { [key: string]: boolean },
  adSlides: AdSlides,
  baseData: FunnelAdsData,
  whatIfData: FunnelAdsData | undefined,
  dateRange: { startDate: Date; endDate: Date },
  isWhatIf: boolean = false,
  allMetrics: Record<string, number> = {},
): echarts.EChartsOption => {
  const activeSeriesConfig = seriesConfig.filter(
    (series) => activeSeries[series.name],
  );

  const hasWhatIfData = isWhatIf && whatIfData;

  const isForecastNeeded =
    hasWhatIfData ||
    (!isWhatIf && Object.values(adSlides).some((value) => value !== 0));

  const data = { ...baseData };

  if (isForecastNeeded && !hasWhatIfData) {
    const slidesSum =
      Object.values(adSlides).reduce((acc, value) => acc + value, 0) || 1;

    for (const series of activeSeriesConfig) {
      const originalData = baseData[series.name];
      const adjustmentFactor = slidesSum / 2 || 0;

      data[`Forecast ${series.name}`] = originalData.map(
        () => adjustmentFactor,
      );
    }
  }

  const filteredData = dates.flatMap((date) =>
    activeSeriesConfig.flatMap((series) => {
      const dateIndex = dates.findIndex((d) => d.getTime() === date.getTime());
      if (dateIndex === -1) {
        console.warn(`Date ${date} not found in dates array.`);
        return [];
      }

      const baseDataPoint = [
        date.getTime(),
        baseData[series.name][dateIndex],
        series.name,
      ] as [number, number, string];

      const dataArray = [baseDataPoint];

      if (isForecastNeeded && activeSeries[series.name]) {
        let forecastValue;

        if (hasWhatIfData) {
          forecastValue = whatIfData?.[series.name][dateIndex] || 0;
        } else if (data[`Forecast ${series.name}`]) {
          forecastValue = data[`Forecast ${series.name}`][dateIndex];
        } else {
          forecastValue = 0;
        }

        const forecastData = [
          date.getTime(),
          forecastValue,
          `Forecast ${series.name}`,
        ] as [number, number, string];

        dataArray.push(forecastData);
      }

      return dataArray;
    }),
  );

  const colors = Object.keys(seriesColors)
    .map((key) => {
      const dataMatch = filteredData.find((data) => data[2] === key);
      if (dataMatch) {
        return seriesColors[key];
      }
      return undefined;
    })
    .filter((color): color is string => color !== undefined);

  return {
    title: {
      text: "Campaign Performance",
      left: "5%",
      top: "24px",
      textStyle: {
        color: "#ffffff",
      },
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      borderRadius: 16,
      renderMode: "html",
      padding: 16,
      position: function (point, params, dom, rect, size) {
        tooltipX = point[0];

        const chartWidth = 1252;
        const centerX = chartWidth / 2;
        const threshold = chartWidth * 0.18;

        if (
          point[0] >= centerX - threshold &&
          point[0] <= centerX + threshold
        ) {
          return [point[0] - size.contentSize[0] / 2, point[1] + 15];
        }

        if (point[0] > centerX) {
          return [point[0] - size.contentSize[0] - 15, point[1]];
        }

        return [point[0] + 15, point[1]];
      },
      formatter: function (params) {
        const tooltipId = `echarts-tooltip-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        const initialContent = `<div id="${tooltipId}">Loading...</div>`;

        tooltipFormatter(params, tooltipX, dateRange, allMetrics)
          .then((content) => {
            const tooltipEl = document.getElementById(tooltipId);
            if (tooltipEl) {
              tooltipEl.innerHTML = content;
            }
          })
          .catch((err) => {
            console.error("Error formatting tooltip:", err);
            const tooltipEl = document.getElementById(tooltipId);
            if (tooltipEl) {
              tooltipEl.innerHTML = `<div>Error loading data</div>`;
            }
          });

        return initialContent;
      },
    },
    singleAxis: {
      type: "time",
      show: false,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    graphic: [
      {
        type: "text",
        left: "15%",
        top: "95%",
        style: {
          text: "Impressions",
          align: "center",
          fill: "#ffffff",
        },
      },
      {
        type: "text",
        left: "50%",
        top: "95%",
        style: {
          text: "Clicks",
          align: "center",
          fill: "#ffffff",
        },
      },
      {
        type: "text",
        left: "83%",
        top: "95%",
        style: {
          text: "Units Sold",
          align: "center",
          fill: "#ffffff",
        },
      },
    ],
    series: [
      {
        type: "themeRiver",
        data: filteredData,
        animation: false,
        label: {
          show: false,
          color: "#ffffff",
          margin: -12,
        },
        color: colors,
        emphasis: {
          disabled: false,
          focus: "self",
          itemStyle: {
            opacity: 1,
          },
        },
        select: {
          disabled: true,
        },
        blur: {
          itemStyle: {
            opacity: 0.2,
          },
        },
        universalTransition: true,
      },
    ],
  };
};
