import { Card, Button } from "antd";
import styles from "./Scheduler.module.scss";
import { mockData } from "./utils";
import { useState } from "react";
import OrdersModal from "@components/PoModal";
import classNames from "classnames";

const Scheduler = ({ styleGrid }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleViewOrders = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div className={styles.scheduler}>
      <div className={classNames(styles.gridContainer, styleGrid)}>
        <div className={styles.schedulerContainer}>
          <h1 className={styles.verticalText}>Scheduler</h1>
        </div>

        {mockData.map((order) => (
          <div key={order.id} className={styles.gridItem}>
            <Card className={styles.card}>
              <div>
                <span className={styles.po}>PO:</span> {order.po.number}
              </div>
              <div>
                <span className={styles.amount}>Amount:</span> {order.po.amount}
              </div>
              <div>
                <span className={styles.amount}>DL Date:</span>{" "}
                {order.po.dlDate}
              </div>
              <div>
                <span className={styles.amount}>COGS:</span> {order.po.cogs}
              </div>
              <div className={styles.soNumber}>
                <span className={styles.po}>SO:</span> {order.so.number}
              </div>
              <div>
                <span className={styles.amount}>Amount:</span> {order.so.amount}
              </div>
              <div>
                <span className={styles.amount}>DL Date:</span>{" "}
                {order.so.dlDate}
              </div>
              <div>
                <span className={styles.amount}>T. Fee:</span> {order.so.tFee}
              </div>
              <Button
                type="link"
                className={styles.viewButton}
                onClick={() => handleViewOrders(order)}
              >
                VIEW ORDERS
              </Button>
            </Card>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <OrdersModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          order={selectedOrder}
        />
      )}
    </div>
  );
};

export default Scheduler;
