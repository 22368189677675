import React, { useState } from "react";
import FinanceFlowChart from "../FinanceFlowChart";
import { nameMappingNoForecast } from "../FinanceFlowChart/utils";
import { SettingsModal } from "../InventoryPerformance/modal";
import Setting from "@assets/icons/setting";
import styles from "./styles.module.scss";

const FinanceChartWithSettings: React.FC = () => {
  const initialDataPoints = Object.keys(nameMappingNoForecast).reduce(
    (acc, key) => {
      acc[key] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const [isForecastedMarket, setIsForecastedMarket] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataPoints, setDataPoints] = useState(initialDataPoints);

  return (
    <div className={styles.containerFinance}>
      <FinanceFlowChart
        dataPoints={dataPoints}
        forecast={true}
        pastdata={true}
      />
      <button
        className={styles.setting__button}
        onClick={() => setIsOpenModal(true)}
      >
        <Setting />
      </button>
      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        setDataPoints={setDataPoints}
        dataPoints={dataPoints}
        showForecast={isForecastedMarket}
        setShowForecast={setIsForecastedMarket}
        isPoModal={false}
        setIsPoModal={() => {}}
        isTableView={false}
        setIsTableView={() => {}}
        isFinance={true}
      />
    </div>
  );
};

export default FinanceChartWithSettings;
