import styles from "./styles.module.scss";
import { CampaignsTable } from "@pages/AdsAnalitics/components/Campaigns/components/Tables";

export const OptimizationWidget = () => {
  return (
    <div className={styles.wrapper}>
      <CampaignsTable showSearch="show" />
    </div>
  );
};
