import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface Step {
  label: string;
  value: string;
}

interface StepperProps {
  currStep: number;
  steps: Step[];
  totalLabel?: string;
  totalValue?: string;
}

export const Stepper: React.FC<StepperProps> = ({
  currStep,
  steps,
  totalLabel,
  totalValue,
}) => {
  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepsLine}>
        {steps.map((step, index) => (
          <div className={styles.stepItem} key={index}>
            <div className={styles.dashedLine} />
            <div
              className={classNames(styles.circle, {
                [styles.active]: currStep === index + 1,
              })}
            >
              {index + 1}
            </div>
            <div
              className={classNames(styles.stepValue, {
                [styles.active]: currStep === index + 1,
              })}
            >
              +{step.value}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.total}>
        {totalLabel} <span className={styles.totalValue}>{totalValue}</span>
      </div>
    </div>
  );
};
