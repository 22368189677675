import classNames from "classnames";
import { Card } from "../Card";
import { ProductActions } from "../ProductActions";
import { ProductChart } from "../ProductChart";
import styles from "./styles.module.scss";
import { useState } from "react";
import { VariantsList } from "../VariantsList";
import { Details } from "../Details";

export const List = ({ data }) => {
  const [variantsState, setVariantsState] = useState({});
  const [detailsState, setDetailsState] = useState({});

  const toggleVariants = (asin) => {
    setVariantsState((prevState) => ({
      ...prevState,
      [asin]: !prevState[asin],
    }));
  };

  const toggleDetails = (asin) => {
    setDetailsState((prevState) => ({
      ...prevState,
      [asin]: !prevState[asin],
    }));
  };

  return (
    <div className={styles.productsList}>
      {data.data.map((item) => {
        const isVariants = variantsState[item.asin] || false;
        const isDetails = detailsState[item.asin] || false;

        return (
          <div className={styles.productWrapper} key={item.asin}>
            <div className={styles.product}>
              <Card
                title={item.title}
                asin={item.asin}
                variations={item.variations}
                img={item.img}
                rating={item.rating}
                value={item.value}
                isVariants={isVariants}
                setIsVariants={() => toggleVariants(item.asin)}
                status={item.status}
                variantsCount={item.variants?.length}
              />
              <ProductChart />
              <ProductActions
                status={item.status}
                isDetails={isDetails}
                setIsDetails={() => toggleDetails(item.asin)}
              />
            </div>
            <div
              className={classNames(styles.collapse, {
                [styles.active]: isVariants || isDetails,
              })}
            >
              {isVariants && <VariantsList data={data} isDetails={isDetails} />}
              {isDetails && <Details />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
