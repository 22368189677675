import { AdsSalesApi } from "@services/ads-sales/ads-sales.api";

export interface FunnelAdsData {
  "Amazon Ads": number[];
  "Amazon Organic": number[];
  "Organic Social": number[];
  DSP: number[];
}

export interface FunnelData {
  baseData: FunnelAdsData;
  whatIfData?: FunnelAdsData;
  allMetrics?: Record<string, number>;
}

export class FunnelService {
  private static adsSalesApi = new AdsSalesApi();

  static getFunnelData = async (
    date_start: string,
    date_end: string,
    isWhatIf: boolean = false,
    saturation: number = 0,
  ): Promise<FunnelData> => {
    try {
      const allMetrics = await this.adsSalesApi.getAllMetrics(
        date_start,
        date_end,
        saturation,
      );

      const baseAmazonAds = [
        allMetrics["ad_impressions"] || 0,
        allMetrics["ad_clicks"] || 0,
        allMetrics["ad_units_sold"] || 0,
      ];

      const baseAmazonOrganic = [
        allMetrics["organic_impressions"] || 0,
        allMetrics["organic_clicks"] || 0,
        allMetrics["organic_units_sold"] || 0,
      ];

      const baseData: FunnelAdsData = {
        "Amazon Ads": baseAmazonAds,
        "Amazon Organic": baseAmazonOrganic,
        "Organic Social": [50, 3, 2],
        DSP: [30, 2, 1],
      };

      let whatIfData: FunnelAdsData | undefined;

      if (isWhatIf && saturation > 0) {
        const hasWhatIfData = Object.keys(allMetrics).some((key) =>
          key.endsWith("_what_if"),
        );

        if (hasWhatIfData) {
          whatIfData = {
            "Amazon Ads": [
              allMetrics["ad_impressions_what_if"] || 0,
              allMetrics["ad_clicks_what_if"] || 0,
              allMetrics["ad_units_sold_what_if"] || 0,
            ],
            "Amazon Organic": [
              allMetrics["organic_impressions_what_if"] || 0,
              allMetrics["organic_clicks_what_if"] || 0,
              allMetrics["organic_units_sold_what_if"] || 0,
            ],
            "Organic Social": [50, 3, 2],
            DSP: [30, 2, 1],
          };
        }
      }

      return {
        baseData,
        whatIfData,
        allMetrics,
      };
    } catch (error) {
      console.error("Error fetching funnel data:", error);
      const emptyData = {
        "Amazon Ads": [0, 0, 0],
        "Amazon Organic": [0, 0, 0],
        "Organic Social": [0, 0, 0],
        DSP: [0, 0, 0],
      };

      return {
        baseData: emptyData,
        whatIfData: undefined,
        allMetrics: {},
      };
    }
  };
}
