import mockImg from "@assets/img/mock3.png";
import AspectsIcon from "@assets/icons/marketIntelligence/Aspect.svg";
import CROIcon from "@assets/icons/marketIntelligence/CRO.svg";
import PricingIcon from "@assets/icons/marketIntelligence/Pricing.svg";
import COOIcon from "@assets/icons/marketIntelligence/COO.svg";
import CFOIcon from "@assets/icons/marketIntelligence/Line_chart.svg";
import CPOIcon from "@assets/icons/marketIntelligence/carbon_diagram.svg";
import OptimizationIcon from "@assets/icons/marketIntelligence/Ad_Optimization.svg";

export const stepperMock = [
  { label: "Step 1", value: "$2,070.59" },
  { label: "Step 2", value: "$2,070.59" },
  { label: "Step 3", value: "$2,070.59" },
  { label: "Step 4", value: "$2,070.59" },
  { label: "Step 5", value: "$2,070.59" },
  { label: "Step 6", value: "$2,070.59" },
  { label: "Step 7", value: "$2,070.59" },
];

export const tabsMock = {
  product_aspects: {
    label: "Product Aspects",
    icon: AspectsIcon,
    ploblem: {
      title: "Unclear Dosage Efficacy",
      text: "The product contains multiple sleep-supporting ingredients, but the exact effectiveness of the formulation for different users may be uncertain. Some users might not experience the desired results.",
    },
    solution: {
      title: "Enhanced Sleep Formula",
      text: "Increase the melatonin dosage slightly or add clinically proven ingredients like GABA to enhance effectiveness and improve customer satisfaction.",
      potential_value: 500,
    },
  },
  CRO: {
    label: "CRO",
    icon: CROIcon,
    item: {
      imgs_gallery: [
        mockImg,
        mockImg,
        mockImg,
        mockImg,
        mockImg,
        mockImg,
        mockImg,
        mockImg,
        mockImg,
      ],
      video_gallery: [
        {
          src: mockImg,
          title:
            "Sugarbear Pro Hair Vitamins - Vegan Gummies for Luscious Hair and Nails",
        },
        {
          src: mockImg,
          title:
            "Sugarbear Pro Hair Vitamins - Vegan Gummies for Luscious Hair and Nails",
        },
        {
          src: mockImg,
          title: "Sugarbear Hair - Helps Maintain Beautiful Hair and Nails",
        },
      ],
      title:
        "Sugarbear Vegan Hair Gummy Vitamins with Biotin, Vitamin C, Vitamin B-12, Zinc for Hair Skin & Nails (1 Month Supply)",
      bullets: [
        "We designed our vitamins for those who were looking for easy to eat vitamins without the struggle of swallowing pills. Nourish your hair from within with our cruelty free gummy bear hair vitamins.*",
        "Scientifically formulated blend of Biotin, Vitamin D, Vitamin E, Vitamin B6, Folate, Calcium, Zinc, Sodium, Iodine and our hair proprietary blend with Inositol.",
        "This certified vegan formula is based on clinical trials that have shown nutrients play an important role in hair health.* No gelatin. No dairy. No gluten. Kosher and Halal Certified. 100 Day Money Back Guarantee with proof of purchase.",
        "Gluten free, gelatin free, dairy free, soy free, nut free, egg free, and wheat free",
        "100 Day Money Back Guarantee with proof of purchase.",
      ],
      terms: [
        "Sugarbear sugar bear hair vitamins minerals loss hairs nails pills vegan.",
      ],
    },
    barrels: {
      listing_value: 45,
      photo_value: 60,
      video_value: 15,
      content_value: null,
    },
  },
  pricing: {
    label: "Pricing",
    icon: PricingIcon,
    discount: 10,
    subscribe: 5,
    coupon: 0,
    toggle: {
      text: "Lorem ipsum ",
      options: ["yes", "no"],
    },
    tdata: {
      "01.01.2025": {
        price: 34.99,
        discount: 0,
        coupon: 0,
      },
      "01.02.2025": {
        price: 34.99,
        discount: 0,
        coupon: 0,
      },
      "01.03.2025": {
        price: 34.99,
        discount: 0,
        coupon: 0,
      },
      "01.04.2025": {
        price: 34.99,
        discount: 5,
        coupon: 5,
      },
      "01.05.2025": {
        price: 34.99,
        discount: 5,
        coupon: 5,
      },
      "01.06.2025": {
        price: 34.99,
        discount: 10,
        coupon: 10,
      },
      "01.07.2025": {
        price: 34.99,
        discount: 10,
        coupon: 10,
      },
    },
  },
  optimization: {
    label: "Ad optimization",
    icon: OptimizationIcon,
  },
  COO: {
    label: "COO",
    icon: COOIcon,
    scheduler: [
      {
        id: 1,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
      {
        id: 2,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
      {
        id: 3,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
      {
        id: 4,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
      {
        id: 5,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
      {
        id: 6,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
      {
        id: 7,
        PO: {
          value: "738291",
          amount: "XXX",
          DL_Date: "01.01.2024",
          COGS: 0.5,
        },
        SO: {
          value: "928391",
          amount: "XXX",
          DL_Date: "01.01.2024",
          T_FREE: "XXX",
        },
      },
    ],
  },
  CFO: {
    label: "CFO",
    icon: CFOIcon,
    data: {
      total_sales: {
        value: 7531933,
        amazon_sales: {
          value: 5711855,
          ad: 4982550,
          organic: 729304,
        },
        other_sales: {
          value: 1820078,
          ad: 0,
          organic: 0,
        },
      },
      goods_sold: {
        value: 1820078,
        amazon_sales: {
          value: 0,
        },
        other_sales: {
          value: 0,
        },
      },
      overhead_cost: {
        value: 4405719,
        amazon_sales: {
          value: 0,
        },
        other_sales: {
          value: 0,
        },
      },
      contribution_profit: 1536370,
      contribution_margin: 20.4,
      OPEX: 225958,
      EBITDA: 1310412,
    },
  },
  CPO: {
    label: "CPO",
    icon: CPOIcon,
    data: [],
  },
};
