import styles from "./styles.module.scss";
interface CardProps {
  title: string;
  bullets: string[];
  terms: string[];
}

export const Card = ({ title, bullets, terms }: CardProps) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.line} />
      <div className={styles.bullets}>
        <h4>About this item</h4>
        <ul>
          {bullets.map((bullet, index) => (
            <li key={index}>
              <span>{bullet}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.terms}>
        <h4>Search terms</h4>
        <ul>
          {terms.map((term, index) => (
            <li key={index}>
              <span>{term}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
