import InventoryPerformance from "@pages/SupplyChain/components/InventoryPerformance";
import styles from "./styles.module.scss";
import Scheduler from "@pages/SupplyChain/components/Scheduler";

export const COOWidget = () => {
  return (
    <div className={styles.wrapper}>
      <InventoryPerformance />
      <div className={styles.scheduller}>
        <Scheduler styleGrid={styles.schedullerGrid} />
      </div>
    </div>
  );
};
