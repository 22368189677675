import { calculateIncrease } from "@pages/AdsAnalitics/components/Campaigns/utils";
import { AdsSalesApi } from "./ads-sales/ads-sales.api";
import { WidgetMetricKey, WidgetMetrics } from "./math/math.service";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WIDGET_METRICS_CONFIG, WidgetConfigItem } from "./math/math.const";
import { SalesPerformanceService } from "./sales-performance/sales-performance.service";

interface MetricProcessingResult {
  value: string;
  originalValue: number;
  difference: string;
}

export interface ChartData {
  date: string;
  value: number;
}

interface ExtendedWidgetData extends IWidget {
  chartData?: ChartData[];
}

export class WidgetClass {
  public widgetCount: number;
  private adsSalesApi: AdsSalesApi;
  private salesPerformanceService: SalesPerformanceService;

  constructor() {
    this.widgetCount = 0;
    this.adsSalesApi = new AdsSalesApi();
    this.salesPerformanceService = new SalesPerformanceService();
  }

  public getWidgetCount(): number {
    return this.widgetCount;
  }

  public setWidgetCount(count: number): void {
    this.widgetCount = count;
  }

  public async getWidgetsMetrics(
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
    isWhatIf: boolean = false,
    saturation: number = 0,
  ): Promise<Partial<WidgetMetrics> & { compare?: Partial<WidgetMetrics> }> {
    const rawMetrics = await this.adsSalesApi.getAllMetrics(
      date_start,
      date_end,
      saturation,
    );

    const metrics = this.adaptApiResponseToWidgetMetrics(rawMetrics, isWhatIf);

    if (!isWhatIf && compareStart && compareEnd) {
      const compareMetrics = await this.adsSalesApi.getWidgetsComparePromise(
        date_start,
        date_end,
        compareStart,
        compareEnd,
      );

      return {
        ...metrics,
        compare: this.adaptApiResponseToWidgetMetrics(
          compareMetrics,
        ) as Partial<WidgetMetrics>,
      };
    }

    return metrics as Partial<WidgetMetrics>;
  }

  private processMetricValue(
    metrics: any,
    key: WidgetMetricKey,
    compareType?: "raw" | "percent",
  ): MetricProcessingResult {
    const cuttedKey =
      key.startsWith("ad") && !metrics[key] ? key.slice(2) : key;
    const value = metrics[cuttedKey] ?? this.generateRandomValueForKey(key);

    let compareValue = "0";
    if (compareType && metrics.compare) {
      const cuttedCompareKey =
        key.startsWith("ad") && !metrics.compare[key] ? key.slice(2) : key;
      compareValue = this.calculateCompareValue(
        metrics,
        cuttedCompareKey,
        value,
        compareType,
      );
    }

    return {
      value: value.toString(),
      originalValue: value,
      difference: compareValue,
    };
  }

  private calculateCompareValue(
    metrics: any,
    key: string,
    currentValue: number,
    compareType?: "raw" | "percent",
  ): string {
    const compareMetric =
      metrics.compare[key] ?? this.generateRandomValueForKey(key);

    if (compareType === "raw") {
      const formattedValue = compareMetric || "0";
      return compareMetric > 0 ? "+" + formattedValue : formattedValue;
    }

    return calculateIncrease(currentValue, compareMetric).increase;
  }

  public async getWidgetsData(
    selectedKeys: WidgetMetricKey[],
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
    compareType?: "raw" | "percent",
    includeChartData?: boolean,
    isWhatIf: boolean = false,
    saturation: number = 0,
  ): Promise<ExtendedWidgetData[]> {
    const metrics = await this.getWidgetsMetrics(
      date_start,
      date_end,
      compareStart,
      compareEnd,
      isWhatIf,
      saturation,
    );

    const chartDataPromises = includeChartData
      ? this.getChartDataForMetrics(
          selectedKeys,
          new Date(date_start),
          new Date(date_end),
        )
      : Promise.resolve([]);

    const chartData = includeChartData ? await chartDataPromises : [];

    return selectedKeys.map((key, index) => {
      const { value, originalValue, difference } = this.processMetricValue(
        metrics,
        key,
        isWhatIf ? undefined : compareType,
      );

      return {
        id: key,
        name: WidgetClass.convertKeyToName(key),
        value,
        originalValue,
        difference: isWhatIf ? "0" : difference,
        key,
        isWhatIf: isWhatIf,
        chartData: includeChartData
          ? this.formatChartData(chartData[index] || [])
          : undefined,
      };
    });
  }

  private async getChartDataForMetrics(
    keys: WidgetMetricKey[],
    startDate: Date,
    endDate: Date,
  ): Promise<number[][]> {
    return Promise.all(
      keys.map(async (key) => {
        const camelKey = this.convertToCamelCase(key);
        const metricKey = WIDGET_METRICS_CONFIG[key] ? key : camelKey;
        try {
          const data = await this.salesPerformanceService.fetchMetricData(
            metricKey,
            startDate.toISOString().split("T")[0],
            endDate.toISOString().split("T")[0],
          );
          if (data.length === 0) {
            return this.generateRandomChartData(startDate, endDate, metricKey);
          }
          return data;
        } catch (error) {
          return this.generateRandomChartData(startDate, endDate, metricKey);
        }
      }),
    );
  }

  private generateRandomChartData(
    startDate: Date,
    endDate: Date,
    key: string,
  ): number[] {
    const days = Math.ceil(
      (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24),
    );
    return Array(days)
      .fill(0)
      .map(() => this.generateRandomValueForKey(key));
  }

  private formatChartData(data: number[]): ChartData[] {
    return data.map((value, index) => ({
      date: new Date(new Date().setDate(new Date().getDate() - index))
        .toISOString()
        .split("T")[0],
      value,
    }));
  }

  private getMetricFromConfig(key: string): WidgetConfigItem {
    const camelKey = this.convertToCamelCase(key);
    const config =
      WIDGET_METRICS_CONFIG[key] || WIDGET_METRICS_CONFIG[camelKey];

    if (!config || !config?.format) {
      console.warn(`No format found for key: ${key} (converted: ${camelKey})`);
    }

    return config;
  }

  formatValue = (key: string, value: number) => {
    const config = this.getMetricFromConfig(key);

    if (!config || !config?.format) {
      return Math.floor(Number(value)).toLocaleString("en-US");
    }

    switch (config.format) {
      case "currency":
        return `$${Math.floor(Number(value)).toLocaleString("en-US")}`;
      case "percent-decimal":
        return `${(Number(value) * 100).toFixed(2)}%`;
      case "percent":
        return `${Math.floor(Number(value) * 100)}%`;
      case "number":
        return Math.floor(Number(value)).toLocaleString("en-US");
      case "decimal":
        return Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case "currency-decimal":
        return `$${Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      default:
        return Math.floor(Number(value)).toLocaleString("en-US");
    }
  };

  private convertToCamelCase = (key: string): string => {
    const baseKey = key.toLowerCase().replace(/_what_if$/, "");

    const specialCases: { [key: string]: string } = {
      roas: "adRoas",
      acos: "adAcos",
      cpc: "adCpc",
      cpm: "adCpm",
    };

    if (specialCases[baseKey]) {
      return specialCases[baseKey];
    }

    return baseKey.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  };

  private adaptApiResponseToWidgetMetrics(
    data: any,
    useWhatIf: boolean = false,
  ): Partial<WidgetMetrics> {
    if (!data) return {};

    const result: Partial<WidgetMetrics> = {};

    const keyMap = new Map<string, any>();
    for (const [key, value] of Object.entries(data)) {
      keyMap.set(key, value);
    }

    for (const [key, value] of Object.entries(data)) {
      if (!useWhatIf && key.endsWith("_what_if")) continue;

      if (
        useWhatIf &&
        !key.endsWith("_what_if") &&
        keyMap.has(`${key}_what_if`)
      )
        continue;

      let processedKey = key;
      if (useWhatIf && key.endsWith("_what_if")) {
        processedKey = key.replace("_what_if", "");
      }

      let normalizedKey = processedKey.replace(/_([a-z])/g, (_, letter) =>
        letter.toUpperCase(),
      );

      if (processedKey.startsWith("ad_")) {
        normalizedKey =
          "ad" + normalizedKey.charAt(2).toUpperCase() + normalizedKey.slice(3);
      } else if (
        ["roas", "acos", "cpc", "cpm", "ctr", "cvr", "cac"].includes(
          processedKey,
        )
      ) {
        normalizedKey =
          "ad" + processedKey.charAt(0).toUpperCase() + processedKey.slice(1);
      }

      result[normalizedKey as keyof WidgetMetrics] = value as number;
    }

    return result;
  }

  public static convertKeyToName(key: string): string {
    return WIDGET_METRICS_CONFIG[key]?.label;
  }

  generateRandomValueForKey(key: string): number {
    const config = this.getMetricFromConfig(key);
    const format = config?.format;

    const lowerKey = key.toLowerCase();

    if (format) {
      switch (format) {
        case "percent":
          return Math.random();

        case "percent-decimal":
          if (lowerKey.includes("ctr")) {
            return 0.005 + Math.random() * 0.025;
          } else if (lowerKey.includes("cvr")) {
            return 0.01 + Math.random() * 0.14;
          } else if (lowerKey.includes("acos")) {
            return 0.1 + Math.random() * 0.3;
          } else if (lowerKey.includes("tacos")) {
            return 0.05 + Math.random() * 0.2;
          } else if (
            lowerKey.includes("rate") ||
            lowerKey.includes("accuracy")
          ) {
            return 0.5 + Math.random() * 0.49;
          } else if (lowerKey.includes("margin")) {
            return 0.2 + Math.random() * 0.4;
          } else if (lowerKey.includes("share")) {
            return 0.05 + Math.random() * 0.25;
          } else {
            return Math.random() * 0.1;
          }

        case "currency":
          if (lowerKey.includes("sales")) {
            return 1000 + Math.floor(Math.random() * 100000);
          } else if (lowerKey.includes("spend")) {
            return 500 + Math.floor(Math.random() * 20000);
          } else if (lowerKey.includes("capital")) {
            return 5000 + Math.floor(Math.random() * 200000);
          } else if (lowerKey.includes("cost")) {
            return 100 + Math.floor(Math.random() * 10000);
          } else if (
            lowerKey.includes("profit") ||
            lowerKey.includes("margin")
          ) {
            return 200 + Math.floor(Math.random() * 50000);
          } else {
            return 100 + Math.floor(Math.random() * 10000);
          }

        case "currency-decimal":
          if (lowerKey.includes("cpc")) {
            return 0.5 + Math.random() * 2.5;
          } else if (lowerKey.includes("cpm")) {
            return 5 + Math.random() * 15;
          } else {
            return 0.1 + Math.random() * 9.9;
          }

        case "number":
          if (lowerKey.includes("impressions")) {
            return 1000 + Math.floor(Math.random() * 1000000);
          } else if (lowerKey.includes("clicks")) {
            return 50 + Math.floor(Math.random() * 10000);
          } else if (lowerKey.includes("orders") || lowerKey.includes("sold")) {
            return 10 + Math.floor(Math.random() * 1000);
          } else if (lowerKey.includes("cart") || lowerKey.includes("add")) {
            return 20 + Math.floor(Math.random() * 5000);
          } else if (lowerKey.includes("count")) {
            return 5 + Math.floor(Math.random() * 100);
          } else {
            return Math.floor(Math.random() * 1000);
          }

        case "decimal":
          if (lowerKey.includes("roas")) {
            return 1.5 + Math.random() * 8.5;
          } else if (lowerKey.includes("roi")) {
            return 0.2 + Math.random() * 1.8;
          } else if (lowerKey.includes("score")) {
            return 3.0 + Math.random() * 2.0;
          } else if (lowerKey.includes("mer")) {
            return 2.0 + Math.random() * 6.0;
          } else if (lowerKey.includes("turnover")) {
            return 2.0 + Math.random() * 10.0;
          } else if (lowerKey.includes("time") || lowerKey.includes("doi")) {
            return 1.0 + Math.random() * 29.0;
          } else {
            return Math.random() * 10;
          }

        default:
          break;
      }
    }

    if (
      lowerKey.includes("sales") ||
      lowerKey.includes("spend") ||
      lowerKey.includes("margin") ||
      lowerKey.includes("profit")
    ) {
      return Math.floor(Math.random() * 10000);
    }

    if (
      lowerKey.includes("ctr") ||
      lowerKey.includes("cvr") ||
      lowerKey.includes("acos") ||
      lowerKey.includes("rate")
    ) {
      return Math.random();
    }

    if (lowerKey.includes("roas")) {
      return Math.random() * 10;
    }

    if (lowerKey.includes("cpc")) {
      return Math.random() * 5;
    }

    return Math.floor(Math.random() * 1000);
  }
}

export const widgetService = new WidgetClass();
