import styles from "./styles.module.scss";
import {
  Line,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { staticData } from "./mock";
import CustomInput from "@components/СustomInput";
import { useState } from "react";
import classNames from "classnames";

export const PrisingWidget = ({ data }) => {
  const { tdata, toggle } = data;
  const [discount, setDiscount] = useState(10);
  const [coupon, setCoupon] = useState(0);
  const [subbox, setSubbox] = useState(0);
  const [selectedToggle, setSelectedToggle] = useState(toggle.options[0]);

  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleСouponChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleSubChange = (value) => {
    setSubbox(value);
  };

  const handleToggleChange = (option) => {
    setSelectedToggle(option);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.chartWrapper}>
          {/* <ResponsiveContainer width="100%" height={338}>
            <ComposedChart
              width={771}
              height={306}
              data={staticData}
              margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
            >
              <CartesianGrid vertical={true} stroke="#5c5c5a" opacity={0.1} />
              <XAxis
                dataKey="date"
                stroke="#5c5c5a"
                padding={{ left: 20, right: 20 }}
              />
              <YAxis
                yAxisId="left"
                axisLine={false}
                tickLine={false}
                domain={[0, 5]}
              />
  
              <YAxis
                yAxisId="right"
                orientation="right"
                axisLine={false}
                tickLine={false}
                domain={[0.2, 0.6]}
                tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
              />
  
              <Tooltip
                cursor={{ stroke: "white", strokeDasharray: "4 4" }}
                formatter={(value: any, name: string) => {
                  if (name === "conversionRate")
                    return [`${(value * 100).toFixed(1)}%`, "Conversion"];
                  return [value, name];
                }}
              />
  
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="unitsSold"
                stroke={chartColors.unitsSold}
                strokeWidth={2}
                dot={false}
                activeDot={{
                  r: 5,
                  fill: "#fff",
                  stroke: chartColors.unitsSold,
                  strokeWidth: 2,
                }}
              />
            </ComposedChart>
          </ResponsiveContainer> */}
          <ResponsiveContainer width="100%" height={338}>
            <ComposedChart
              width={771}
              height={306}
              data={staticData}
              margin={{ top: 20, right: 0, left: 0, bottom: 10 }}
            >
              <CartesianGrid vertical={true} stroke="#5c5c5a" opacity={0.1} />
              <XAxis
                dataKey="date"
                stroke="#5c5c5a"
                padding={{ left: 20, right: 20 }}
              />

              <Tooltip
                cursor={{ stroke: "white", strokeDasharray: "4 4" }}
                formatter={(value: any, name: string) => [
                  `${(value * 100).toFixed(1)}%`,
                  name === "conversionRate" ? "Conversion" : "Engagement",
                ]}
              />
              <Line
                type="monotone"
                dataKey="engagement"
                stroke="#FE5858"
                strokeWidth={2}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="conversionRate"
                stroke="#5295E0"
                strokeWidth={2}
                dot={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.table}>
          <div className={styles.tableScrollWrapper}>
            <table className={styles.tableWrapper}>
              <thead>
                <tr>
                  <th></th>
                  {Object.entries(tdata).map(([date]) => (
                    <th key={date}>{date}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {["price", "discount", "coupon"].map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    {Object.values(tdata).map((values, index) => (
                      <td key={index}>
                        {key === "price" ? (
                          <span>${values[key].toFixed(2)}</span>
                        ) : (
                          <span>{values[key]}%</span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <h3>Price</h3>
        <div className={styles.discount}>
          <CustomInput
            label="Discount, %"
            value={discount}
            onChange={handleDiscountChange}
            className={styles.discountInput}
          />
        </div>
        <div className={styles.subscribe}>
          <h4>Subscribe to the discount</h4>
          <div className={styles.subRow}>
            <button
              className={classNames(styles.subscribeButton, {
                [styles.active]: subbox === 5,
              })}
              onClick={() => handleSubChange(5)}
            >
              5%
            </button>
            <button
              className={classNames(styles.subscribeButton, {
                [styles.active]: subbox === 10,
              })}
              onClick={() => handleSubChange(10)}
            >
              10%
            </button>
            <button
              className={classNames(styles.subscribeButton, {
                [styles.active]: subbox === 15,
              })}
              onClick={() => handleSubChange(15)}
            >
              15%
            </button>
          </div>
        </div>
        <div className={styles.coupon}>
          <CustomInput
            label="Coupon"
            value={coupon}
            onChange={handleСouponChange}
            className={styles.couponInput}
          />
        </div>
        <div className={styles.toggle}>
          <div className={styles.toggleText}>{toggle.text}</div>
          <div className={styles.toggleOptions}>
            {toggle.options.map((option, index) => (
              <div
                key={index}
                className={classNames(styles.toggleOption, {
                  [styles.active]: selectedToggle === option,
                })}
                onClick={() => handleToggleChange(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
