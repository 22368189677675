import styles from "./styles.module.scss";
import classNames from "classnames";
import { Tabs } from "antd";
import { widgetComponentsMap } from "./widgetMapping";

export const TabsComponent = ({
  setActiveTab,
  setActiveStep,
  activeTab,
  data,
}) => {
  const handleTabClick = (key: string, index: number) => {
    if (key === "CPO") return;
    setActiveTab(key);
    setActiveStep(index + 1);
  };

  return (
    <Tabs defaultActiveKey="0" className={styles.tabs}>
      {Object.keys(data).map((key, index) => {
        const tab = data[key];
        const WidgetComponent = widgetComponentsMap[key];
        const isDisabled = key === "CPO";

        return (
          <Tabs.TabPane
            tab={
              <div
                className={classNames(styles.tabItem, {
                  [styles.active]: activeTab === key,
                  [styles.disabled]: isDisabled,
                })}
                onClick={() => handleTabClick(key, index)}
              >
                {tab.icon && (
                  <img
                    src={tab.icon}
                    className={classNames(styles.tabIcon, {
                      [styles.active]: activeTab === key,
                    })}
                  />
                )}
                <span>{tab.label}</span>
              </div>
            }
            key={key}
            disabled={isDisabled}
          >
            <div className={styles.tabContent}>
              {WidgetComponent ? (
                <WidgetComponent data={tab} />
              ) : (
                <div>No component found</div>
              )}
            </div>
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};
