export const staticData = [
  { date: "Jun", unitsSold: 2.4, conversionRate: 0.45 },
  { date: "Jul", unitsSold: 3.2, conversionRate: 0.48 },
  { date: "Aug", unitsSold: 3.1, conversionRate: 0.46 },
  { date: "Sep", unitsSold: 2.2, conversionRate: 0.43 },
  { date: "Oct", unitsSold: 2.7, conversionRate: 0.47 },
  { date: "Nov", unitsSold: 2.6, conversionRate: 0.41 },
  { date: "Dec", unitsSold: 3.0, conversionRate: 0.44 },
  { date: "Jan", unitsSold: 2.8, conversionRate: 0.39 },
  { date: "Feb", unitsSold: 2.7, conversionRate: 0.42 },
  { date: "Mar", unitsSold: 3.1, conversionRate: 0.48 },
  { date: "Apr", unitsSold: 2.1, conversionRate: 0.36 },
  { date: "May", unitsSold: 1.5, conversionRate: 0.34 },
  { date: "Jun", unitsSold: 2.4, conversionRate: 0.52 },
  { date: "Jul", unitsSold: 3.6, conversionRate: 0.41 },
  { date: "Aug", unitsSold: 2.8, conversionRate: 0.43 },
];

export const chartColors = {
  unitsSold: "#6B4DBA",
  conversionRate: "#52d4a9",
};

export const formatCurrency = (value: number) => `$${value.toLocaleString()}`;
