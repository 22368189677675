import React, { useState, useMemo } from "react";
import styles from "./styles.module.scss";
import Setting from "@assets/icons/setting";
import FinanceFlowChart from "../FinanceFlowChart";
import { SettingsModal } from "../InventoryPerformance/modal";
import { ExpandableTable } from "@components/ExpandebleTable";
import { nameMapping } from "../FinanceFlowChart/utils";
import { financeData, generateFinanceColumns } from "./mock";
import { useComputedTimeRangesStore } from "src/store/useComputedTimeRangesStore";

const CashBlocks: React.FC = () => {
  const initialDataPoints = Object.keys(nameMapping).reduce(
    (acc, key) => {
      acc[key] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const [isForecastedMarket, setIsForecastedMarket] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataPoints, setDataPoints] = useState(initialDataPoints);
  const [showForecast, setShowForecast] = useState(false);
  const [isPoModal, setIsPoModal] = useState(false);
  const { computedTimeRange } = useComputedTimeRangesStore();
  const [isTableView, setIsTableView] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedKeys((prev) => [...prev, record.key]);
    } else {
      setExpandedKeys((prev) => prev.filter((k) => k !== record.key));
    }
  };

  const rowSelections = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {},
  };

  const dynamicFinanceColumns = useMemo(
    () => generateFinanceColumns(computedTimeRange),
    [computedTimeRange],
  );
  const scrollX =
    computedTimeRange.length > 0 && typeof computedTimeRange[0] === "number"
      ? 1000
      : 100;

  return (
    <div className={styles.perfomanceBlock}>
      <div className={styles.marketPerformanceWrapper}>
        {isTableView ? (
          <div
            className={styles.cashTable}
            style={{ maxWidth: 1020, overflowX: "auto" }}
          >
            <h2 className={styles.cashTitle}>Cash Flow</h2>
            <ExpandableTable
              data={financeData}
              columns={dynamicFinanceColumns}
              withCustomScroll
              rowSelection={rowSelections}
              expandable={{
                expandedRowKeys: expandedKeys,
                onExpand: handleExpand,
                rowExpandable: (record) =>
                  Array.isArray(record.children) && record.children.length > 0,
              }}
              scroll={{ x: scrollX }}
            />
          </div>
        ) : (
          <FinanceFlowChart
            showForecast={isForecastedMarket}
            title={true}
            dataPoints={dataPoints}
            forecast={true}
            pastdata={true}
          />
        )}
        <button
          className={styles.setting__button}
          onClick={() => setIsOpenModal(true)}
        >
          <Setting />
        </button>
      </div>
      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        setDataPoints={setDataPoints}
        dataPoints={dataPoints}
        showForecast={showForecast}
        setShowForecast={setIsForecastedMarket}
        isPoModal={isPoModal}
        setIsPoModal={setIsPoModal}
        isCash={true}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
      />
    </div>
  );
};

export default CashBlocks;
