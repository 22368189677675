import styles from "./styles.module.scss";
interface MaterialsProps {
  imgs_gallery: string[];
  video_gallery: { src: string; title: string }[];
}

export const Materials = ({ imgs_gallery, video_gallery }: MaterialsProps) => {
  console.log("Materials", imgs_gallery, video_gallery);

  return (
    <div className={styles.wrapper}>
      <div className={styles.gallery}>
        <img src={imgs_gallery[0]} alt="Gallery" className={styles.main} />
        <div className={styles.imgsList}>
          {imgs_gallery.slice(1).map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Gallery ${index}`}
              className={styles.img}
            />
          ))}
        </div>
      </div>
      <div className={styles.videoList}>
        {video_gallery.map((video, index) => (
          <div key={index} className={styles.videoItem}>
            <img
              src={video.src}
              alt={`Video ${index}`}
              className={styles.video}
            />
            <div className={styles.videoTitle}>{video.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
