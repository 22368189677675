import { WidgetMetricKey } from "./math.service";

export const WIDGET_METRIC_KEYS = [
  // ----- TOTAL -----
  "totalSales",
  "totalSpend",
  "totalImpressions",
  "totalClicks",
  "totalUnitsSold",
  "totalCtr",
  "totalCvr",
  "totalOrders",
  "totalNtbOrders",
  "tacos",
  "mer",
  "roi",
  // ----- AD -----
  "adSales",
  "adSpend",
  "adImpressions",
  "adClicks",
  "adUnitsSold",
  "adCtr",
  "adCvr",
  "adOrders",
  "adAcos",
  "adRoas",
  "adCpc",
  "adCpm",
  "adAddToCart",
  "adTimeInBudget",
  "adPOS",

  // ----- ORGANIC -----
  "organicSales",
  "organicImpressions",
  "organicClicks",
  "organicCartAdds",
  "organicUnitsSold",
  "organicCtr",
  "organicCvr",
  "organicOrders",
  "organicPOS",

  // ----- CFO -----
  "availableCapital",
  "frozenCapital",
  "borrowedCapital",
  "lostSales",
  "grossMargin",
  "opex",
  "contribution",
  "profitEbitda",

  // ----- COO -----
  "fbaInStockRate",
  "fbtInStockRate",
  "3plInStockRate",
  "shippingCosts",
  "transportCost",
  "safetyStock",
  "lostSalesCfo",
  "storageCosts",
  "forecastingAccuracy",
  "contributionMargin",
  "inventoryTurnover",
  "availableCapitalCOO",
  "frozenCapitalCOO",
  "leadTime",
  "DOI",

  // --- MARKET ---
  "marketTotalSales",
  "brandMarketShare",
  "marketAveragePrice",
  "marketUnitsSold",
  "marketASINcount",
  "marketPromotionValue",
  "marketPromotionCount",
  "marketReviewScore",
  "marketPOS",
  "marketAdSpend",

  // --- (REST) ---
  "reorderPoint",
  "inTransit",
  "receiving",
  "noUnitsSold",
  "unitsSold",

  //--MarketSales
  "crestSales",
  "lumineuxSales",
  "inoProSales",
  "guruNandaSales",
  "keidocSales",

  // --Cash---
  "amazonAdSales",
  "amazonFees",
  "amazonOrganicSales",
  "amazonSpend",
  "organicSocial",
  "organicSocialSpend",
  "dsp",
  "shippingAndHandling",
  "cogs",
  "cashflow",
] as const;

type WidgetFormat =
  | "currency"
  | "number"
  | "percent"
  | "decimal"
  | "currency-decimal"
  | "percent-decimal";

export type WidgetConfigItem = {
  label: string;
  format: WidgetFormat;
  info?: string;
};

export type WidgetConfig = Record<
  (typeof WIDGET_METRIC_KEYS)[number],
  WidgetConfigItem
>;

export const WIDGET_METRICS_CONFIG: WidgetConfig = {
  // ----- TOTAL -----
  totalSales: {
    label: "Total Sales",
    format: "currency",
    info: "Total sales generated from both ads and organic listings.",
  },
  totalSpend: {
    label: "Total Spend",
    format: "currency",
    info: "Total amount of money spent on ads.",
  },
  totalImpressions: {
    label: "Total Impressions",
    format: "number",
    info: "Number of times your product is displayed to users, both through ads and organic listings.",
  },
  totalClicks: {
    label: "Total Clicks",
    format: "number",
    info: "Number of times users clicked on your product, both through ads and organic listings.",
  },
  totalUnitsSold: {
    label: "Total Units Sold",
    format: "number",
    info: "Number of units sold of your product, both through ads and organic listings.",
  },
  totalCtr: {
    label: "Total CTR",
    format: "percent-decimal",
    info: "Percentage of total impressions that resulted in clicks.",
  },
  totalCvr: {
    label: "Total CVR",
    format: "percent-decimal",
    info: "Percentage of total clicks that resulted in a sale.",
  },
  totalOrders: {
    label: "Total Orders",
    format: "number",
    info: "Number of orders placed for your product, both through ads and organic listings.",
  },
  totalNtbOrders: {
    label: "Total NTB Orders",
    format: "number",
    info: "The percentage of impressions that result in a click",
  },
  tacos: {
    label: "TACOS",
    format: "percent",
    info: "Percentage of total advertising spend relative to total sales (both ads and organic).",
  },
  mer: {
    label: "MER",
    format: "decimal",
    info: "Ratio of total revenue to total marketing costs, indicating overall marketing effectiveness.",
  },
  roi: {
    label: "ROI",
    format: "decimal",
    info: "Ratio of total revenue to total marketing costs, indicating overall marketing effectiveness.",
  },
  // ----- AD -----
  adSales: {
    label: "Ad Sales",
    format: "currency",
    info: "Total sales generated from ad clicks.",
  },
  adSpend: {
    label: "Ad Spend",
    format: "currency",
    info: "Total amount of money spent on ads.",
  },
  adImpressions: {
    label: "Ad Impressions",
    format: "number",
    info: "Number of times an ad is displayed to users.",
  },
  adClicks: {
    label: "Ad Clicks",
    format: "number",
    info: "Number of times users clicked on an ad.",
  },
  adUnitsSold: {
    label: "Ad Units Sold",
    format: "number",
    info: "Number of units sold after clicking on an ad.",
  },
  adCtr: {
    label: "Ad CTR",
    format: "percent-decimal",
    info: "Percentage of ad impressions that resulted in clicks.",
  },
  adCvr: {
    label: "Ad CVR",
    format: "percent-decimal",
    info: "Percentage of ad clicks that resulted in a sale.",
  },
  adOrders: {
    label: "Ad Orders",
    format: "number",
    info: "Number of orders placed after clicking on an ad.",
  },
  adAcos: {
    label: "ACOS",
    format: "percent",
    info: "Percentage of ad spend relative to sales generated from ads.",
  },
  adRoas: {
    label: "ROAS",
    format: "decimal",
    info: "Ratio of revenue generated to amount spent on ads.",
  },
  adCpc: {
    label: "CPC",
    format: "currency-decimal",
    info: "Average cost paid for each ad click.",
  },
  adCpm: {
    label: "CPM",
    format: "currency-decimal",
    info: "Measures the cost for every number ad impressions.",
  },
  adAddToCart: {
    label: "Ad Add to Cart",
    format: "number",
    info: "Number of times users added a product to their cart after clicking on an ad.",
  },
  adTimeInBudget: {
    label: "Time in Budget",
    format: "percent",
    info: "The percentage of time a campaign remains within its allocated budget during a set period.",
  },
  adPOS: {
    label: "Ad POS",
    format: "number",
    info: "The point of sale for ads",
  },

  // ----- ORGANIC -----
  organicSales: {
    label: "Organic Sales",
    format: "currency",
    info: "Total sales generated without paid promotion.",
  },
  organicImpressions: {
    label: "Organic Impressions",
    format: "number",
    info: "Number of times a product is displayed to users without paid promotion.",
  },
  organicClicks: {
    label: "Organic Clicks",
    format: "number",
    info: "Number of times users clicked on a product listing without paid promotion.",
  },
  organicCartAdds: {
    label: "Organic Add to Cart",
    format: "number",
    info: "Number of times users added a product to their cart without paid promotion.",
  },
  organicUnitsSold: {
    label: "Organic Units Sold",
    format: "number",
    info: "Number of units sold without paid promotion.",
  },
  organicCtr: {
    label: "Organic CTR",
    format: "percent-decimal",
    info: "Percentage of organic impressions that resulted in clicks.",
  },
  organicCvr: {
    label: "Organic CVR",
    format: "percent-decimal",
    info: "Percentage of organic clicks that resulted in a sale.",
  },
  organicOrders: {
    label: "Organic Orders",
    format: "number",
    info: "Number of orders placed without paid promotion.",
  },
  organicPOS: {
    label: "Organic POS",
    format: "number",
    info: "The point of sale for organic listings",
  },

  // ----- CFO -----
  availableCapital: {
    label: "Available Capital",
    format: "currency",
    info: "Capital that is available for the business",
  },
  frozenCapital: {
    label: "Frozen Capital",
    format: "currency",
    info: "Capital that is frozen in inventory",
  },
  borrowedCapital: {
    label: "Borrowed Capital",
    format: "currency",
    info: "Capital that is borrowed from external sources",
  },
  lostSales: {
    label: "Lost Sales",
    format: "currency",
    info: "Sales that are lost due to stockouts",
  },
  grossMargin: {
    label: "Gross Margin",
    format: "currency",
    info: "The difference between revenue and cost of goods sold",
  },
  opex: {
    label: "OPEX",
    format: "currency",
    info: "Operating expenses are the costs that are incurred in the normal course of business",
  },
  contribution: {
    label: "Contribution Profit",
    format: "currency",
    info: "The profit that remains after all direct costs have been deducted from revenue",
  },
  profitEbitda: {
    label: "EBITDA",
    format: "currency",
    info: "Earnings before interest, taxes, depreciation, and amortization",
  },

  // ----- COO -----
  fbaInStockRate: {
    label: "FBA In Stock Rate",
    format: "percent-decimal",
    info: "The percentage of inventory that is in stock",
  },
  fbtInStockRate: {
    label: "FBT In Stock Rate",
    format: "percent-decimal",
    info: "The percentage of inventory that is in stock",
  },
  "3plInStockRate": {
    label: "3PL In Stock Rate",
    format: "percent-decimal",
    info: "The percentage of inventory that is in stock",
  },
  shippingCosts: {
    label: "Shipping Costs",
    format: "currency",
    info: "Costs associated with shipping inventory",
  },
  transportCost: {
    label: "Transport Cost",
    format: "currency",
    info: "Costs associated with transporting inventory",
  },
  safetyStock: {
    label: "Safety Stock",
    format: "number",
    info: "Minimum amount of stock that should be on hand",
  },
  lostSalesCfo: {
    label: "Lost Sales (CFO)",
    format: "currency",
    info: "Sales that are lost due to stockouts",
  },
  storageCosts: {
    label: "Storage Costs",
    format: "currency",
    info: "Costs associated with storing inventory",
  },
  forecastingAccuracy: {
    label: "Forecasting Accuracy",
    format: "percent-decimal",
    info: "The accuracy of the forecasted demand",
  },
  contributionMargin: {
    label: "Contribution Margin",
    format: "percent-decimal",
    info: "The percentage of revenue that exceeds the variable costs of production",
  },
  inventoryTurnover: {
    label: "Inventory Turnover",
    format: "number",
    info: "Number of times inventory is sold or used in a period",
  },
  availableCapitalCOO: {
    label: "Available Capital",
    format: "currency",
    info: "Available capital for the business",
  },
  frozenCapitalCOO: {
    label: "Frozen Capital",
    format: "currency",
    info: "Capital that is frozen in inventory",
  },
  leadTime: {
    label: "Lead Time",
    format: "number",
    info: "The amount of time it takes to receive inventory",
  },
  DOI: {
    label: "DOI",
    format: "number",
    info: "The number of days it takes to sell inventory",
  },

  // --- MARKET INTELLIGENCE ---
  marketTotalSales: {
    label: "Market Total Sales",
    format: "currency",
    info: "Total revenue generated by all products in the market.",
  },
  brandMarketShare: {
    label: "Brand Market Share",
    format: "percent-decimal",
    info: "Percentage of total market sales attributed to a specific brand.",
  },
  marketAveragePrice: {
    label: "Market Average Price",
    format: "currency",
    info: "Average price of products sold in the market.",
  },
  marketUnitsSold: {
    label: "Market Units Sold",
    format: "number",
    info: "Total number of units sold in the market.",
  },
  marketASINcount: {
    label: "Market ASIN count",
    format: "number",
    info: "Total count of unique ASINs (Amazon Standard Identification Numbers) available in the market.",
  },
  marketPromotionValue: {
    label: "Market Promotion Value",
    format: "currency",
    info: "Total value of promotions offered in the market.",
  },
  marketPromotionCount: {
    label: "Market Promotion Count",
    format: "number",
    info: "Total count of promotions offered in the market.",
  },
  marketReviewScore: {
    label: "Market Review Score",
    format: "decimal",
    info: "Average rating given by customers for products in the market, typically on a scale from number to number stars.",
  },
  marketPOS: {
    label: "Market POS",
    format: "number",
    info: "The point of sale in the market",
  },
  marketAdSpend: {
    label: "Market Ad Spend",
    format: "currency",
    info: "Total amount of money spent on advertising in the market.",
  },

  // --- REST ---
  reorderPoint: {
    label: "Reorder Point",
    format: "currency",
    info: "Minimum quantity of stock that should be on hand",
  },
  inTransit: {
    label: "In Transit",
    format: "currency",
    info: "Goods that are in the process of being transported",
  },
  receiving: {
    label: "Receiving",
    format: "currency",
    info: "Goods that are being received",
  },
  noUnitsSold: {
    label: "No Units Sold",
    format: "currency",
    info: "Units that have not been sold",
  },
  unitsSold: {
    label: "Units Sold",
    format: "currency",
    info: "Units that have been sold",
  },

  //--MarketSales
  crestSales: {
    label: "Crest",
    format: "currency",
    info: "Sales for Crest brand",
  },
  lumineuxSales: {
    label: "Lumineux",
    format: "currency",
    info: "Sales for Lumineux brand",
  },
  inoProSales: {
    label: "InoPro",
    format: "currency",
    info: "Sales for InoPro brand",
  },
  guruNandaSales: {
    label: "GuruNanda",
    format: "currency",
    info: "Sales for GuruNanda brand",
  },
  keidocSales: {
    label: "Keidoc",
    format: "currency",
    info: "Sales for Keidoc brand",
  },

  //--Cash---
  amazonAdSales: {
    label: "Amazon Ad Sales",
    format: "currency",
    info: "Revenue from Amazon Ads",
  },
  amazonFees: {
    label: "Amazon Fees",
    format: "currency",
    info: "Fees charged by Amazon",
  },
  amazonOrganicSales: {
    label: "Amazon Organic Sales",
    format: "currency",
    info: "Organic sales on Amazon",
  },
  amazonSpend: {
    label: "Amazon Spend",
    format: "currency",
    info: "Spend on Amazon",
  },
  organicSocial: {
    label: "Organic Social",
    format: "currency",
    info: "Sales from organic social channels",
  },
  organicSocialSpend: {
    label: "Organic Social Spend",
    format: "currency",
    info: "Spend on organic social channels",
  },
  dsp: {
    label: "DSP",
    format: "currency",
    info: "Demand-Side Platform related cost or sales",
  },
  shippingAndHandling: {
    label: "Shipping & Handling",
    format: "currency",
    info: "Costs for shipping & handling",
  },
  cogs: {
    label: "COGS",
    format: "currency",
    info: "Cost of Goods Sold",
  },
  cashflow: {
    label: "Cashflow",
    format: "currency",
    info: "Net cash flow",
  },
};

type WidgetMetricGroup = {
  title: string;
  keys: WidgetMetricKey[];
};

export const WIDGET_METRIC_GROUPS: WidgetMetricGroup[] = [
  {
    title: "Total",
    keys: [
      "totalSales",
      "totalSpend",
      "totalImpressions",
      "totalClicks",
      "totalUnitsSold",
      "totalCtr",
      "totalCvr",
      "totalOrders",
      "totalNtbOrders",
      "tacos",
      "mer",
      "roi",
      "lostSales",
      "contribution",
    ],
  },
  {
    title: "Ad",
    keys: [
      "adSales",
      "adSpend",
      "adImpressions",
      "adClicks",
      "adUnitsSold",
      "adCtr",
      "adCvr",
      "adOrders",
      "adAcos",
      "adRoas",
      "adCpc",
      "adCpm",
      "adAddToCart",
      "adTimeInBudget",
    ] as WidgetMetricKey[],
  },
  {
    title: "Organic",
    keys: [
      "organicSales",
      "organicImpressions",
      "organicClicks",
      "organicUnitsSold",
      "organicCtr",
      "organicCvr",
      "organicOrders",
      "organicCartAdds",
    ] as WidgetMetricKey[],
  },
  {
    title: "COO",
    keys: [
      "fbaInStockRate",
      "fbtInStockRate",
      "3plInStockRate",
      "storageCosts",
      "transportCost",
      "safetyStock",
      "shippingCosts",
      "forecastingAccuracy",
      "contributionMargin",
      "inventoryTurnover",
      "lostSalesCfo",
    ] as WidgetMetricKey[],
  },
  {
    title: "Market",
    keys: [
      "marketTotalSales",
      "brandMarketShare",
      "marketAveragePrice",
      "marketUnitsSold",
      "marketASINcount",
      "marketPromotionValue",
      "marketPromotionCount",
      "marketReviewScore",
      "marketPOS",
      "marketAdSpend",
    ] as WidgetMetricKey[],
  },
  {
    title: "CFO",
    keys: [
      "availableCapital",
      "frozenCapital",
      "borrowedCapital",
      "grossMargin",
      "opex",
      "profitEbitda",
    ] as WidgetMetricKey[],
  },

  {
    title: "Brands",
    keys: [
      "crestSales",
      "lumineuxSales",
      "inoProSales",
      "guruNandaSales",
      "keidocSales",
    ] as WidgetMetricKey[],
  },
];
