import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Arrow from "@assets/icons/Arrow";
import { Slider } from "antd";
import "./range.scss";
import classNames from "classnames";
import { RangeCard } from "./RangeCard";
import { MetricCard } from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder/MetricCard";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { Goals, useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import ModalBox from "@components/ModalBox";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import InfoBlue from "@assets/icons/InfoBlue";
import PencilIcon from "@assets/icons/PencilIcon";
import { getInputLabel, formatDate, metrics } from "./utils";
import Clock from "@assets/icons/clock";
import { HitsoryInteractionModal } from "./HistoryInteraction";
import { useGlobalResetStore } from "src/store/global-reset.store";
import { widgetService } from "@services/widget.service";

const isKeysExist = (keys: {
  performance: string[];
  campaign: string[];
  targeting: string[];
}) => {
  return (
    keys.performance.length > 0 ||
    keys.campaign.length > 0 ||
    keys.targeting.length > 0
  );
};

interface AdSpendProps {
  isSmall?: boolean;
  onlyChart?: boolean;
}

export const AdSpend = ({
  isSmall = false,
  onlyChart = false,
}: AdSpendProps) => {
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const { resetTriggered, resetComplete, setHasChanges } =
    useGlobalResetStore();
  const { keys, goals, setGoals, adSlides, setAdSlides, isWhatIfEnabled } =
    useAdsAnaliticsStore((state) => ({
      keys: state.keys,
      goals: state.goals,
      setGoals: state.setGoals,
      adSlides: state.adSlides,
      setAdSlides: state.setAdSlides,
      isWhatIfEnabled: state.isWhatIfEnabled,
    }));

  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);
  const [adSpend, setAdSpend] = useState<number>(0);
  const [bids, setBids] = useState<number>(0);
  const [time, setTime] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);
  const [rest, setRest] = useState<number>(0);
  const [weeks, setWeeks] = useState<number>(0);
  const [openedMetric, setOpenedMetric] = useState<IWidget>();
  const [goalsInputs, setGoalsInputs] = useState<Goals>({
    acos: 0,
    dailySpend: 0,
    monthlySales: 0,
  });
  const [historyIteration, setHistoryIteration] = useState(false);
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const initialValues = useRef({
    adSpend: 0,
    bids: 0,
    time: 0,
    top: 0,
    pages: 0,
    rest: 0,
    weeks: 0,
  });

  const handleAdSpendChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setAdSpend(currentValue);
    handleAdSlidesChange("adSpend", currentValue);
  };

  const handleBidsChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setBids(currentValue);
    handleAdSlidesChange("adBids", currentValue);
  };

  const handleTimeChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setTime(currentValue);
    handleAdSlidesChange("adTime", currentValue);
  };

  const handleTopChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setTop(currentValue);
    handleAdSlidesChange("adTop", currentValue);
  };

  const handleProductPagesChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setPages(currentValue);
    handleAdSlidesChange("adProductPages", currentValue);
  };

  const handleRestOfSearchChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setRest(currentValue);
    handleAdSlidesChange("adRestOfSearch", currentValue);
  };

  const handleAdSlidesChange = (key: string, value: number) => {
    setAdSlides({
      ...adSlides,
      [key]: value,
    });
  };

  const resetAdSpend = () => {
    setAdSpend(0);
    handleAdSlidesChange("adSpend", 0);
  };

  const resetBids = () => {
    setBids(0);
    handleAdSlidesChange("adBids", 0);
  };

  const resetTime = () => {
    setTime(0);
    handleAdSlidesChange("adTime", 0);
  };

  const resetTop = () => {
    setTop(0);
    handleAdSlidesChange("adTop", 0);
  };

  const resetPages = () => {
    setPages(0);
    handleAdSlidesChange("adProductPages", 0);
  };

  const resetRest = () => {
    setRest(0);
    handleAdSlidesChange("adRestOfSearch", 0);
  };

  const resetWeeks = () => {
    setWeeks(0);
    handleAdSlidesChange("week", 0);
  };

  const resetAllSliders = () => {
    resetAdSpend();
    resetBids();
    resetTime();
    resetTop();
    resetPages();
    resetRest();
    resetWeeks();
  };

  const handleChangeGoal = (key: string, value: number) => {
    setGoalsInputs({
      ...goals,
      [key]: value,
    });
  };

  const handleSaveGoals = () => {
    setGoals(goalsInputs);
    setOpenedMetric(undefined);
  };

  useEffect(() => {
    const haveValuesChanged =
      adSpend !== initialValues.current.adSpend ||
      bids !== initialValues.current.bids ||
      time !== initialValues.current.time ||
      top !== initialValues.current.top ||
      pages !== initialValues.current.pages ||
      rest !== initialValues.current.rest ||
      weeks !== initialValues.current.weeks;

    setHasChanges(haveValuesChanged);
  }, [adSpend, bids, time, top, pages, rest, weeks, setHasChanges]);

  useEffect(() => {
    if (resetTriggered && isWhatIfEnabled) {
      resetAllSliders();
      resetComplete();
    }
  }, [resetTriggered, isWhatIfEnabled, resetComplete]);

  return (
    <div className={styles.container}>
      {keys && isKeysExist(keys) && !isSmall && (
        <div className={styles.keys}>
          <h2>Selected targets</h2>
          <div className={styles.keys__list}>
            {Object.values(keys)
              .flat()
              .map((key, index) => (
                <div key={index} className={styles.keys__item}>
                  {key}
                </div>
              ))}
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        {!onlyChart && (
          <div
            className={classNames(styles.spend, isSmall && styles.only__spend)}
          >
            <h3>Ad Spend, %</h3>
            {!isAdvancedOpen && isWhatIfEnabled && (
              <div className={styles.range}>
                <div className={styles.range__input}>
                  <Slider
                    min={-70}
                    max={300}
                    step={10}
                    value={adSpend >= 0 ? [0, adSpend] : [adSpend, 0]}
                    defaultValue={[0, 0]}
                    onChange={handleAdSpendChange}
                    className={classNames(
                      "custom-range",
                      adSpend >= 0 ? "range__slider" : "range__sliderNegative",
                    )}
                    range
                  />
                </div>
                <div className={styles.range__result}>
                  <div className={styles.inputGroup}>
                    <input
                      type="number"
                      value={adSpend}
                      onChange={(event) =>
                        setAdSpend(Number(event.target.value))
                      }
                      className={styles.range__inputNumber}
                    />
                  </div>
                </div>
              </div>
            )}
            {!isWhatIfEnabled && (
              <RangeCard
                value={adSpend}
                onChange={handleAdSpendChange}
                isWhatIf={isWhatIfEnabled}
                onReset={resetAdSpend}
              />
            )}
            <div className={styles.advanced}>
              <button
                className={classNames(
                  styles.advanced__button,
                  isAdvancedOpen && styles.active,
                )}
                onClick={() => {
                  setIsAdvancedOpen(!isAdvancedOpen);
                }}
              >
                Advanced Options <Arrow />
              </button>
            </div>
            {isAdvancedOpen && (
              <>
                <div className={styles.cards}>
                  <RangeCard
                    title="Ad Spend, %"
                    value={adSpend}
                    onChange={handleAdSpendChange}
                    isWhatIf={isWhatIfEnabled}
                    min={-70}
                    max={300}
                    onReset={resetAdSpend}
                  />
                  <RangeCard
                    title="Bids, %"
                    value={bids}
                    onChange={handleBidsChange}
                    isWhatIf={isWhatIfEnabled}
                    onReset={resetBids}
                  />
                  <RangeCard
                    title="Time in budget, %"
                    value={time}
                    onChange={handleTimeChange}
                    isWhatIf={isWhatIfEnabled}
                    min={0}
                    onReset={resetTime}
                  />
                  <RangeCard
                    title="Top of search, %"
                    value={top}
                    onChange={handleTopChange}
                    isWhatIf={isWhatIfEnabled}
                    min={0}
                    onReset={resetTop}
                  />
                  <RangeCard
                    title="Product Pages"
                    value={pages}
                    onChange={handleProductPagesChange}
                    isWhatIf={isWhatIfEnabled}
                    min={0}
                    onReset={resetPages}
                  />
                  <RangeCard
                    title="Rest of Search"
                    value={rest}
                    onChange={handleRestOfSearchChange}
                    isWhatIf={isWhatIfEnabled}
                    min={0}
                    onReset={resetRest}
                  />
                </div>
                <div className={styles.actionButtons}>
                  {!isSmall && !historyIteration && (
                    <button
                      className={styles.apply}
                      onClick={() => {
                        setHistoryIteration(true);
                      }}
                    >
                      APPLY changes
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {!isSmall && (
          <div
            className={classNames(
              styles.charts,
              onlyChart && styles.only__chart,
            )}
          >
            <div className={styles.metrics}>
              {metrics.map((metric) =>
                !isWhatIfEnabled ? (
                  <div className={styles.metric__card}>
                    <div className={styles.header}>
                      <h4>{metric.name}</h4>
                      <span
                        className={classNames(
                          styles.metric__change,
                          Number(metric.difference) > 0
                            ? styles.positive
                            : styles.negative,
                        )}
                      >
                        {widgetService
                          .formatValue(metric.key, Number(metric.difference))
                          .replace("%", "") + "%"}
                      </span>
                    </div>
                    <span>{metric.value}</span>
                  </div>
                ) : (
                  <div className={styles.metric}>
                    <MetricCard
                      key={metric.id}
                      metric={metric}
                      metricColor="#5295E0"
                      goalValue={Number(
                        metric.key === "acos"
                          ? goals.acos / 100
                          : goals[metric.key],
                      )}
                    />
                    {goals[metric.key] === 0 ? (
                      <button
                        className={styles.goals}
                        onClick={() => {
                          setOpenedMetric(metric);
                        }}
                      >
                        Set up goals
                      </button>
                    ) : (
                      <div className={styles.goals__container}>
                        <span>
                          Goal:{" "}
                          {Number(goals[metric.key]).toLocaleString("en-US")}{" "}
                          {metric.key === "acos" ? "%" : "$"}
                        </span>
                        <button
                          onClick={() => {
                            setOpenedMetric(metric);
                          }}
                        >
                          <PencilIcon />
                        </button>
                      </div>
                    )}
                  </div>
                ),
              )}
            </div>
            {historyIteration && (
              <button
                className={styles.view__iteration}
                onClick={() => {
                  setIsOpenHistory(!isOpenHistory);
                }}
              >
                <Clock /> View 3 previous iterations
              </button>
            )}
            <HitsoryInteractionModal
              isOpen={isOpenHistory}
              setIsOpen={setIsOpenHistory}
            />
          </div>
        )}
        <ModalBox
          isOpen={!!openedMetric}
          onClose={() => setOpenedMetric(undefined)}
          title={openedMetric?.name}
          className={styles.modal}
        >
          <div className={styles.input__container}>
            <span>{getInputLabel(openedMetric)}</span>
            <input
              type="number"
              value={goalsInputs[openedMetric?.key]}
              onChange={(event) =>
                handleChangeGoal(openedMetric?.key, Number(event.target.value))
              }
              className={styles.input}
            />
          </div>
          <div className={styles.date}>
            <div className={styles.date__item}>
              <span>Start date</span>
              <span>{formatDate(dateRange.startDate)}</span>
            </div>
            <div className={styles.date__item}>
              <span>End date</span>
              <span>{formatDate(dateRange.endDate)}</span>
            </div>
          </div>
          <div className={styles.info}>
            <InfoBlue />
            Affecting only selected targets
          </div>
          <button className={styles.save} onClick={handleSaveGoals}>
            Save Changes
          </button>
        </ModalBox>
      </div>
    </div>
  );
};
