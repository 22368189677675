import { AspectsWidget } from "../AspectsWidget";
import { CROWidget } from "../CROWidget";
import { PrisingWidget } from "../PrisingWidget";
import { OptimizationWidget } from "../OptimizationWidget";
import { COOWidget } from "../COOWidget";
import { CFOWidget } from "../CFOWidget";
import { CPOWidget } from "../CPOWidget";

export const widgetComponentsMap = {
  product_aspects: AspectsWidget,
  CRO: CROWidget,
  pricing: PrisingWidget,
  optimization: OptimizationWidget,
  COO: COOWidget,
  CFO: CFOWidget,
  CPO: CPOWidget,
};
