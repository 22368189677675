import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const data = [
  { subject: "Effectiveness", A: 9 },
  { subject: "Price", A: 3 },
  { subject: "Ingredients", A: 7 },
  { subject: "Taste", A: 6 },
  { subject: "Health & Safety", A: 3 },
  { subject: "Convenience", A: 4 },
  { subject: "Trust", A: 6 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          width: 0,
        }}
      ></div>
    );
  }
  return null;
};

export const RadarChartWidget = () => {
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <RadarChart data={data}>
          <PolarGrid
            gridType="polygon"
            stroke="#5c5c5a"
            strokeOpacity={0.3}
            radialLines
          />
          <PolarAngleAxis
            dataKey="subject"
            tick={{ fill: "#ffffff", fontSize: 12 }}
          />
          <PolarRadiusAxis
            angle={90}
            domain={[0, 10]}
            tick={false}
            axisLine={false}
            stroke="#5c5c5a"
            tickCount={6}
          />
          <Radar
            name="Score"
            dataKey="A"
            stroke="#4f9dfc"
            fill="#4f9dfc"
            fillOpacity={0.2}
            strokeWidth={2}
            activeDot={({ cx, cy }) => (
              <circle
                cx={cx}
                cy={cy}
                r={5}
                fill="#4f9dfc"
                stroke="var(--nyle-white)"
                strokeWidth={2}
              />
            )}
          />
          <Tooltip
            content={<CustomTooltip active={undefined} payload={undefined} />}
            cursor={{ stroke: "transparent", strokeWidth: 2 }}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};
