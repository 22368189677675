import { useState } from "react";
import FinanceFlowChart from "@pages/SupplyChain/components/FinanceFlowChart";
import styles from "./styles.module.scss";
import classNames from "classnames";

export const CFOWidget = ({ data }) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const toLabel = (text: string) =>
    text
      .replace(/_/g, " ")
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (s) => s.toUpperCase());

  const toggleExpand = (key: string) => {
    setExpandedKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key],
    );
  };

  const renderRow = (label: string, value: any, keyPath: string, level = 0) => {
    const isExpandable =
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value) &&
      Object.keys(value).some((k) => k !== "value");

    const isExpanded = expandedKeys.includes(keyPath);
    const hasValue = value && typeof value.value === "number";

    const formattedValue =
      typeof value === "number"
        ? keyPath.includes("margin")
          ? `${value.toLocaleString()}%`
          : `$${value.toLocaleString()}`
        : hasValue
          ? keyPath.includes("margin")
            ? `${value.value.toLocaleString()}%`
            : `$${value.value.toLocaleString()}`
          : "";

    const isHighlighted = ["overhead_cost", "opex"].includes(
      label.toLowerCase(),
    );

    return (
      <div key={keyPath} className={styles.row}>
        <div
          className={styles.rowHeader}
          style={{ paddingLeft: `${level * 1.2}rem` }}
        >
          {isExpandable ? (
            <span
              className={classNames(
                "ant-table-row-expand-icon",
                isExpanded
                  ? "ant-table-row-expand-icon-expanded"
                  : "ant-table-row-expand-icon-collapsed",
              )}
              onClick={() => toggleExpand(keyPath)}
              style={{
                color: isExpanded ? "var(--nyle-white)" : "#5C5C5A",
                borderColor: isExpanded ? "var(--nyle-white)" : "#5C5C5A",
                background: "transparent",
              }}
            />
          ) : (
            ""
          )}

          <span className={styles.label}>{toLabel(label)}</span>
          {formattedValue && (
            <span
              className={classNames(styles.value, {
                [styles.negative]:
                  (typeof value === "number" && value < 0) ||
                  (hasValue && value.value < 0),
              })}
              style={{
                color: isHighlighted ? "#ff5a5a" : undefined,
              }}
            >
              {formattedValue}
            </span>
          )}
        </div>

        {isExpandable && isExpanded && (
          <div className={styles.subItems}>
            {Object.entries(value).map(([subKey, subValue]) =>
              subKey === "value"
                ? null
                : renderRow(
                    subKey,
                    subValue,
                    `${keyPath}.${subKey}`,
                    level + 1,
                  ),
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.aside}>
        <div className={styles.asideTitle}>
          <span>PNL Type</span>
          <span>As Is</span>
        </div>
        <div className={styles.accList}>
          {Object.entries(data.data).map(([key, value]) =>
            renderRow(key, value, key),
          )}
        </div>
      </div>
      <div className={styles.chartWrapper}>
        <FinanceFlowChart title={true} />
      </div>
    </div>
  );
};
