import React from "react";
import styles from "./styles.module.scss";
import bg from "src/assets/img/canvas_bg.png";
import { useCanvaStore } from "src/store/canva/canva.state";
import { GridStack } from "gridstack";
import "./gridstack-custom.scss";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import classNames from "classnames";
import ScrollContainer from "react-indiana-drag-scroll";
import CanvasEmpty from "@assets/icons/canvasEmpty";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { useSectionStore } from "src/store/croModule.state";
import { useGridStack } from "src/store/canva/useGridStack";
import { useZoom } from "src/utils/useZoom";
import { ZoomControls } from "./components/Zoom";
import { WhatIfToggle } from "./components/WhatIfToggle";
import { GridElement } from "./components/CanvaElements";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { OptimizationSlider } from "./components/Optimization";

interface SizeChangeConfig {
  id: string;
  size: { width?: number; height?: number };
  gridRef: React.RefObject<GridStack>;
}

export const updateElementSize = ({ id, size, gridRef }: SizeChangeConfig) => {
  const element = document.querySelector(`[data-id='${id}']`) as HTMLElement;
  if (element && gridRef.current) {
    const grid = gridRef.current;
    let width = parseInt(element.getAttribute("gs-w") || "1");
    let height = parseInt(element.getAttribute("gs-h") || "1");

    if (size.width) width = size.width;
    if (size.height) height = size.height;

    grid.update(element, {
      w: width,
      h: height,
    });
  }
};

export const Canva: React.FC = () => {
  const {
    elements,
    updateElementPosition,
    isWhatIf,
    setIsWhatIf,
    removeElement,
    isOpenElements,
    isZoomDisabled,
  } = useCanvaStore();
  const { asinSelected: asin } = useDashboardStore();

  const {
    setIsWhatIfEnabled: setAnaliticsWhatIf,
    goals,
    isWhatIfEnabled,
  } = useAdsAnaliticsStore();
  const { changeWhatIf: setCroWhatIf } = useSectionStore();

  const { gridRef, gridContainerRef } = useGridStack(
    updateElementPosition,
    elements,
  );
  const { zoom, zoomIn, zoomOut, setZoom } = useZoom(0.2);

  const handleWhatIf = (whatIf: boolean) => {
    [setCroWhatIf, setAnaliticsWhatIf, setIsWhatIf].forEach((fn) => fn(whatIf));
  };

  const handleDoubleClick = (elementId: string) => {
    if (isZoomDisabled) return;
    const element = document.getElementById(`grid-item-${elementId}`);
    const container = document.querySelector(".canva");
    const gridContainer = document.querySelector(".grid-stack");

    if (element && container && gridContainer) {
      const zoomLevel = 0.7;
      setZoom(zoomLevel);

      setTimeout(() => {
        const containerRect = container.getBoundingClientRect();

        const elementOffsetLeft = element.offsetLeft;
        const elementOffsetTop = element.offsetTop;

        const elementCenterX =
          (elementOffsetLeft + element.offsetWidth / 2) * zoomLevel;
        const elementCenterY =
          (elementOffsetTop + element.offsetHeight / 2) * zoomLevel;

        const scrollX = elementCenterX - containerRect.width / 2;
        const scrollY = elementCenterY - containerRect.height / 2;

        container.scrollTo({
          left: scrollX,
          top: scrollY,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  const isOptimizationEnabled =
    Object.values(goals).some((goal) => {
      console.log("goal", goal);
      return goal !== 0;
    }) && isWhatIfEnabled;

  return (
    <div
      className={classNames(styles.container, {
        [styles.colapsed]: isOpenElements,
      })}
    >
      <ZoomControls zoom={zoom} handleZoomIn={zoomIn} handleZoomOut={zoomOut} />
      <WhatIfToggle isWhatIf={isWhatIf} onChange={handleWhatIf} />

      {elements.length === 0 && (
        <div className={styles.empty}>
          <CanvasEmpty />
          <span>Add elements by clicking “+”</span>
        </div>
      )}
      <ScrollContainer
        className={classNames(styles.area, "canva")}
        style={{ backgroundImage: `url(${bg})` }}
        ignoreElements=".grid-stack-item"
        vertical
        horizontal
        hideScrollbars={false}
      >
        <div
          className={classNames(styles.canva__container, "grid-stack")}
          ref={gridContainerRef}
          style={{
            transform: `scale3D(${zoom}, ${zoom}, 1)`,
            transformOrigin: "0 0 0",
            transition: "transform 0.1s",
          }}
        >
          {elements.map((element) => (
            <GridElement
              key={element.id}
              element={element}
              onRemove={removeElement}
              gridRef={gridRef}
              asin={asin}
              isWhatIf={isWhatIf}
              onDoubleClick={handleDoubleClick}
              zoom={zoom}
            />
          ))}
        </div>
      </ScrollContainer>
      {isOptimizationEnabled && <OptimizationSlider />}
    </div>
  );
};
